<template>
	<PageHeader :title="'ROZPOČTY PROJEKTU (část C)' + nazevProjektu" />

	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<div class="content">

		<Message type="error" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">

			<CastCExcel
				v-if="tabSelected == 1"
				:projekt="projekt"
				@uploaded="updateFile($event)"
			/>

			<CastC1
				v-if="tabSelected === 2"
				:projekt="projekt"
				:data="c1"
			/>

			<CastC2
				v-if="tabSelected === 3"
				:projekt="projekt"
				:data="c2"
			/>

			<CastC3
				v-if="tabSelected === 4"
				:projekt="projekt"
				:data="c3"
				:pozicePrimaPece="pozicePrimaPece"
				:specifikacePrimaPece="specifikacePrimaPece"
				:poziceNeprimaPece="poziceNeprimaPece"
				:specifikaceNeprimaPece="specifikaceNeprimaPece"
				:dosazeneVzdelani="dosazeneVzdelani"
			/>

			<CastC4
				v-if="tabSelected === 5"
				:projekt="projekt"
				:data="c4"
				:pozicePrimaPece="pozicePrimaPece"
				:specifikacePrimaPece="specifikacePrimaPece"
				:poziceNeprimaPece="poziceNeprimaPece"
				:specifikaceNeprimaPece="specifikaceNeprimaPece"
				:dosazeneVzdelani="dosazeneVzdelani"
			/>

			<CastC5
				v-if="tabSelected === 6"
				:projekt="projekt"
				:data="c5"
				:pozicePrimaPece="pozicePrimaPece"
				:specifikacePrimaPece="specifikacePrimaPece"
				:poziceNeprimaPece="poziceNeprimaPece"
				:specifikaceNeprimaPece="specifikaceNeprimaPece"
				:dosazeneVzdelani="dosazeneVzdelani"
			/>

		</template>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'
import CiselnikyService from '@/services/CiselnikyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import CastC1 from '@/components/Projekty/CastC1'
import CastC2 from '@/components/Projekty/CastC2'
import CastC3 from '@/components/Projekty/CastC3'
import CastC4 from '@/components/Projekty/CastC4'
import CastC5 from '@/components/Projekty/CastC5'
import CastCExcel from '@/components/Projekty/CastCExcel'

export default {
	name: 'Projekt-C',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		CastC1,
		CastC2,
		CastC3,
		CastC4,
		CastC5,
		CastCExcel,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			error: '',

			projekt: {},
			projektLoaded: false,
			ciselnikyLoaded: false,

			pozicePrimaPece: [],
			poziceNeprimaPece: [],
			specifikacePrimaPece: [],
			specifikaceNeprimaPece: [],
			dosazeneVzdelani: [],

			tabSelected: -1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Export/import XLSX',
				},
				{
					id: 2,
					title: 'Rozpočet podle zdrojů',
					subtitle: 'Část C1',
					hidden: true,
				},
				{
					id: 3,
					title: 'Rozpočet podle nákl. položek',
					subtitle: 'Část C2',
					hidden: true,
				},
				{
					id: 4,
					title: 'Pracovníci HPP',
					subtitle: 'Část C3',
					hidden: true,
				},
				{
					id: 5,
					title: 'Pracovníci DPČ',
					subtitle: 'Část C4',
					hidden: true,
				},
				{
					id: 6,
					title: 'Pracovníci DPP',
					subtitle: 'Část C5',
					hidden: true,
				},

			],
		}
	},

	computed: {
		...authComputed,

		dataLoaded() {
			return this.projektLoaded && this.ciselnikyLoaded
		},

		nazevProjektu() {
			return (
				this.dataLoaded
				? ` - ${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},
	},

	methods: {

		changeTab(tabId) {
			if (tabId === 0) {
				// zpět na projekt
				this.$router.push({ name: 'Projekt', params: { id: this.id } })
			}
			this.tabSelected = tabId
		},

		updateFile(file) {
			this.projekt.id_priloha_c = file.nazev
			this.projekt.priloha_c_puvodni_nazev = file.puvodni_nazev

			this.loadData()
		},

		loadCiselniky() {
			CiselnikyService
			.getAll()
			.then(response => {
				if (response.data.success) {
					this.pozicePrimaPece = response.data.data.pozice_prima_pece
					this.poziceNeprimaPece = response.data.data.pozice_neprima_pece
					this.specifikacePrimaPece = response.data.data.pozice_prima_pece_spec
					this.specifikaceNeprimaPece = response.data.data.pozice_neprima_pece_spec
					this.dosazeneVzdelani = response.data.data.dosazene_vzdelani

					this.ciselnikyLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadData() {
			this.projektLoaded = false

			ProjektyService
			.getProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projekt = response.data.data.projekt
					this.c1 = response.data.data.c1
					this.c2 = response.data.data.c2
					this.c3 = response.data.data.c3
					this.c4 = response.data.data.c4
					this.c5 = response.data.data.c5

					if (this.projekt.id_priloha_c) {
						this.tabSelected = 1
						this.tabItems.forEach(item => { if (item.id >=2 && item.id <=6) { item.hidden = false }})
					} else {
						this.tabSelected = 1
					}

					this.projektLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

	},


	created() {
		this.loadData()
		this.loadCiselniky()
	},

}
</script>
