<template>
	<div class="current-user__menu" v-show="open">
		<div class="current-user__name">
			Přihlášený uživatel: <strong>{{ name }}</strong>
		</div>
		<div class="current-user__options">
			<button
				class="button button--elastic"
				@click="logout"
			>
				<font-awesome-icon icon="times" class="button__icon" />
				Odhlásit
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CurrentUserMenu',

	props: {
		open: Boolean,
		name: String
	},

	methods: {
		logout() {
			this.$store.dispatch('logout')
			.then(() => {
			 	this.$router.push({ name: 'Logout' });
			})
		}
	}
}
</script>
