<template>
	<div class="sort-arrows">
		<button
			@click="sort(field, 'asc')"
		>
			↓
		</button>
		<button
			@click="sort(field, 'desc')"
		>
			↑
		</button>
	</div>
</template>

<script>
export default {
	name: 'SortArrows',

	props: {
		field: String,
		options: Array,
		sort: Function,
	},

}
</script>
