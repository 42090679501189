<template>
	<div class="filters">

		<div class="filter mr-5">
			<label class="filter__label">Dotační výbor - pro <strong>označené projekty</strong> hromadně nastavit:</label>

			<div>
				<button
					class="button button--outline button--small mr-2"
					@click="nastavitDotacniVybor(true)"
				>
					Zpřístupnit členům dotačního výboru
				</button>
				<button
					class="button button--danger button--outline button--small"
					@click="nastavitDotacniVybor(false)"
				>
					Odebrat přístup
				</button>
			</div>

		</div>

		<div class="filter">
			<label class="filter__label">Krajský koordinátor a externí hodnotitelé - pro <strong>označené projekty</strong> hromadně nastavit:</label>

			<div>
				<select
					class="filter__input mr-2"
					v-model="akce"
					@change="zmenaAkce"
				>
					<option value="">- vyberte akci -</option>
					<option disabled>--------------</option>
					<option value="krajsky_koordinator">PŘIDAT krajského koordinátora (hodnotí)</option>
					<option value="krajsky_komentator">PŘIDAT krajského koordinátora (jen se vyjadřude)</option>
					<option value="ext_hodnotitel">PŘIDAT externího hodnotitele (na první volnou pozici)</option>
					<option disabled>--------------</option>
					<option value="odebrat_krajsky_koordinator">ODEBRAT krajského koordinátora (hodnotí)</option>
					<option value="odebrat_krajsky_komentator">ODEBRAT krajského koordinátora (jen se vyjadřude)</option>
					<option value="odebrat_ext_hodnotitel1">ODEBRAT externího hodnotitele 1</option>
					<option value="odebrat_ext_hodnotitel2">ODEBRAT externího hodnotitele 2</option>
					<option value="odebrat_ext_hodnotitel3">ODEBRAT externího hodnotitele 3</option>
				</select>

				<select
					v-if="akce === 'krajsky_koordinator' || akce === 'krajsky_komentator' || akce === 'ext_hodnotitel'"
					class="filter__input mr-2"
					v-model="hodnotitel"
				>
					<option value="">- vyberte osobu -</option>
					<option disabled>-------------</option>
					<option
						v-for="hod in hodnotitele"
						:key="hod.id"
						:value="hod.id"
					>
						{{ hod.jmeno }}
					</option>
				</select>

				<button
					class="button button--outline button--small mr-2"
					@click="nastavitHodnotitele"
				>
					Provést akci
				</button>
			</div>

		</div>

	</div>
</template>

<script>
import ProjektyService from '@/services/ProjektyService'

export default {
	name: 'NastaveniHodnotitelu',

	props: {
		krajskyKoordinator: {
			type: Array,
			default: [],
		},
		externiHodnotitel: {
			type: Array,
			default: [],
		},
		vybraneProjekty: {
			type: Array,
			default: [],
		},
	},

	data() {
		return {
			akce: '',
			hodnotitel: '',
		}
	},

	computed: {
		hodnotitele() {
			switch (this.akce) {
				case 'krajsky_koordinator':
				case 'krajsky_komentator':
					return this.krajskyKoordinator

				case 'ext_hodnotitel':
					return this.externiHodnotitel

				default:
					return []
			}
		}
	},

	methods: {

		zmenaAkce() {
			this.hodnotitel = ''
		},

		// updateFilter() {
		// 	this.$emit('updateFilter', {
		// 		dotacniRok: this.dotacniRok,
		// 		organizace: this.organizace,
		// 		kod: this.kod,
		// 		nazev: this.nazev,
		// 		typ: this.typ,
		// 		stav: this.stav,
		// 	})
		// },

		nastavitHodnotitele() {
			if (!this.akce) return
			if ((this.akce === 'krajsky_koordinator' || this.akce === 'krajsky_komentator' || this.akce === 'ext_hodnotitel') && !this.hodnotitel) return

			const data = {
				id_projektu: this.vybraneProjekty,
				id_hodnotitele: this.hodnotitel ? Number(this.hodnotitel) : null,
			}

			switch (this.akce) {
				case 'krajsky_koordinator':
				case 'krajsky_komentator':
				case 'ext_hodnotitel':
					data.typ_hodnotitele = this.akce
					break

				case 'odebrat_krajsky_koordinator':
				case 'odebrat_krajsky_komentator':
				case 'odebrat_ext_hodnotitel1':
				case 'odebrat_ext_hodnotitel2':
				case 'odebrat_ext_hodnotitel3':
					data.typ_hodnotitele = this.akce.slice(8)
					break

				default:
					return
			}

			console.log(data)

      ProjektyService
      .nastavitHodnotitele(data)
      .then(response => {
        if (!response.data.success) {
          this.error = response.data.error
        }
				// aktualizujeme prehled projektu v rodicovske komponente
				this.$emit('update')
      })
      .catch(error => {
        this.error = error.message
      })

		},

    nastavitDotacniVybor(newValue) {
			if (this.vybraneProjekty.length === 0) return;

      const data = (
        newValue
        ? { nastavit: this.vybraneProjekty }
        : { vymazat: this.vybraneProjekty }
      )

      ProjektyService
      .nastavitDotacniVybor(data)
      .then(response => {
        if (!response.data.success) {
          this.error = response.data.error
				}
				// aktualizujeme prehled projektu v rodicovske komponente
				this.$emit('update')
      })
      .catch(error => {
        this.error = error.message
      })
    },

	},

}
</script>
