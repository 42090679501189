import ApiClient from './ApiClient'

export default {

	// getProjekty() {
	// 	return ApiClient.get(`/projekty/`)
	// },

	// getProjekt(id) {
	// 	return ApiClient.get(`/projekty/${id}`)
	// },

	exportProjektoveZadosti(dotacniRok) {
		return ApiClient.get(
			`/export-projektove-zadosti/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	exportInformaceOrganizace(dotacniRok) {
		return ApiClient.get(
			`/export-informace-o-organizaci/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

}
