<template>
	<div class="panelgrid__panel">
		<div class="panelgrid__content">
			<h2 class="panelgrid__title">Přílohy žádostí</h2>
			<p>ZIP obsahující přilohy připojené k žádostem organizací ve vybraném dotačním roce.</p>
		</div>
		<div class="panelgrid__buttons">
			<select
				class="form__input form__input--small mr-2"
				:value="dotacniRok"
			>
				<option
					v-for="rok in dotacniRoky"
					:key="rok"
				>
					{{ rok }}
				</option>
			</select>

			<a
				class="button button--outline button--small"
				href="https://api.dotace-protidrogovapolitika.cz/export-files/export-zadosti-prilohy-2022.zip"
			>
				Stáhnout ZIP
			</a>
		</div>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'

import ExportyService from '@/services/ExportyService'
import { saveAs } from 'file-saver'

import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'HromadnyExportPrilohyZadosti',

	components: {
		Message,
		Loading,
	},

	props: {
		dotacniRoky: {
			type: Array,
			default: [],
		},
		aktualniRok: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			error: '',
			dotacniRok: null,
		}
	},

	computed: {
		...authComputed,
	},

	methods: {
		// download() {
		// 	ExportyService
		// 	.exportProjektoveZadosti(this.dotacniRok)
		// 	.then(response => {
		// 		saveAs(new Blob([response.data]), `export-projektove-zadosti-${this.dotacniRok}.xlsx`)
		// 	})
		// 	.catch(error => {
		// 		// neco
		// 	})
		// },
	},

	created() {
		this.dotacniRok = this.aktualniRok
	},
}
</script>
