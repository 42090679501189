<template>

	<PageHeader title="ŽÁDOSTI" />

	<Message type="error" v-if="error">
    {{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

  <FiltrZadosti
    :filterValues="datagridFilter"
    @updateFilter="updateDatagridFilter($event)"
  />

	<table v-if="dataLoaded" class="datagrid mt-4">
		<thead>
			<tr>
        <th class="w15">Stav žádosti</th>
        <th class="w10 alc">Dotační rok</th>
        <th class="w60">Organizace</th>
        <th class="w15 alc">Datum prvního<br>odeslání žádosti</th>
			</tr>
		</thead>
		<tbody>
      <tr v-if="zadostiResult.length === 0">
        <td colspan="4" class="alc p-5 color-danger">Nebyly nalezeny žádné žádosti.</td>
      </tr>

			<tr
				v-for="zadost in zadostiResult"
				:key="zadost.id"
				@click="zobrazitZadost(zadost.id)"
			>
				<td>{{ zadost.stav_nazev }}</td>
				<td class="alc">{{ zadost.dotacni_rok }}</td>
				<td><strong>{{ zadost.nazev_organizace }}</strong></td>
				<td class="alc">{{ formatDateCZ(zadost.datum_prvniho_odeslani_zadosti) }}</td>
			</tr>
		</tbody>
	</table>


</template>

<script>
import {
	STAV_ZADOSTI_PODAVANI,
	STAV_ZADOSTI_UZAMCENO,
	STAV_ZADOSTI_VRACENO,
	STAV_ZADOSTI_SCHVALENO,
	STAV_ZADOSTI_ZAMITNUTO,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import { formatDateCZ } from '@/utils/format.js'

import ZadostiService from '@/services/ZadostiService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

import FiltrZadosti from '@/components/Toolbar/FiltrZadosti'

export default {
  name: 'Zadosti',

  components: {
    PageHeader,
    Message,
    Loading,
    FiltrZadosti,
  },

  data() {
    return {
      error: '',

      dataLoaded: false,
      zadosti: [],

      datagridFilter: {},
    }
  },

  computed: {
    ...authComputed,

    zadostiResult() {
      return this.zadosti.filter(zadost => {
        let result

        // zobrazujeme jen projekty, ktere nejsou rozpracovane
        result = zadost.stav !== this.STAV_ZADOSTI_PODAVANI

        // dotacni rok
        if (this.datagridFilter?.dotacniRok) {
          result = (
            result
            && zadost.dotacni_rok
            && zadost.dotacni_rok == this.datagridFilter.dotacniRok
          )
        }

        // nazev organizace
        if (this.datagridFilter?.organizace) {
          result = (
            result
            && zadost.nazev_organizace
            && zadost.nazev_organizace.toLowerCase().includes(this.datagridFilter.organizace.toLowerCase())
          )
        }

        // stav zadosti
        if (this.datagridFilter?.stav) {
          result = (
            result
            && zadost.stav_zadosti
            && zadost.stav_zadosti == this.datagridFilter.stav
          )
        }

        // vratime, zda projekt splnuje kriteria
        return result
      })
    }
  },

  methods: {
    formatDateCZ,


    updateDatagridFilter(filterValues) {
      this.datagridFilter = filterValues
    },


    zobrazitZadost(id) {
      this.$router.push({ name: 'ZadostAdmin', params: { id: id } })
    },

    loadData() {
      ZadostiService
      .getZadosti()
      .then(response => {
        if (response.data.success) {
          this.zadosti = response.data.data
          this.dataLoaded = true
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

  },

  created() {
    // constants
		this.STAV_ZADOSTI_PODAVANI  = STAV_ZADOSTI_PODAVANI
		this.STAV_ZADOSTI_UZAMCENO  = STAV_ZADOSTI_UZAMCENO
		this.STAV_ZADOSTI_VRACENO   = STAV_ZADOSTI_VRACENO
		this.STAV_ZADOSTI_SCHVALENO = STAV_ZADOSTI_SCHVALENO
		this.STAV_ZADOSTI_ZAMITNUTO = STAV_ZADOSTI_ZAMITNUTO

    this.loadData()
  }
}
</script>