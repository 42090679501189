import ApiClient from './ApiClient'

export default {

	getInformaceById(id) {
		return ApiClient.get(`/informace-organizace/${id}`)
	},

	getInformace(year, idOrganizace) {
		return ApiClient.get(`/informace-organizace/${year}/${idOrganizace}`)
	},

	updateInformace(zadost) {
		return ApiClient
			.put('/informace-organizace/' + zadost.id, zadost)
	},

	getZmenyKeSchvaleni() {
		return ApiClient.get(`/informace-organizace/?stav_formulare_a=2`)
	},

	getInformaceOrganizacePrehled(idOrganizace) {
		return ApiClient.get(`/informace-organizace-prehled/${idOrganizace}`)
	},

}
