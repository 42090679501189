<template>
	<section>

		<SectionHeader title="STAŽENÍ ČÁSTI A" />

		<div class="content">

			<Message type="warning">
				Nezapomeňte část A opatřit podpisem statutárního zástupce (elektronicky nebo fyzicky) a odeslat datovou schránkou, nebo v listinné podobě na adresu Úřadu vlády ČR, nebo osobně odevzdat na podatelně Úřadu vlády ČR.
			</Message>

			<div class="buttons mt-3">
				<button
					class="button button--outline flex-shrink-0"
					@click="stahnoutPDF"
				>
					<font-awesome-icon icon="download" class="button__icon" />
					Stáhnout "Část A" jako PDF
				</button>
			</div>

		</div>

	</section>
</template>

<script>
import ZadostiService from '@/services/ZadostiService'
import { saveAs } from 'file-saver'

import Message from '@/components/Common/Message'
import SectionHeader from '@/components/Common/SectionHeader'

export default {
	name: 'ZadostStazeniPDF',

	components: {
		Message,
		SectionHeader,
	},

	props: {
		idZadosti: {
			type: Number,
			required: true,
		},
		file: {
			type: Object,
			default: null,
		},
	},

	methods: {

		stahnoutPDF() {
			ZadostiService
			.stahnoutPDF(this.idZadosti)
			.then((response) => {
				saveAs(new Blob([response.data]), 'zadost-cast-A.pdf')
			})
		},

	},

}
</script>
