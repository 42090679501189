<template>

	<HodnoceniDetail
    :koordinatorId="userId"
    :dotacniRok="2022"
  />

</template>

<script>

import { authComputed } from '@/store/helpers.js'
import { formatNumberCZ, formatDateCZ } from '@/utils/format.js'

import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

import HodnoceniDetail from '@/components/Hodnoceni/HodnoceniDetail'

export default {
  name: 'Hodnoceni',

  components: {
    PageHeader,
    Message,
    Loading,
    HodnoceniDetail,
  },

  data() {
    return {
      error: '',
      dataLoaded: false,
    }
  },

  computed: {
    ...authComputed,

  },

  methods: {
    formatNumberCZ,
    formatDateCZ,

  },

  created() {
    // this.loadData()
  }
}
</script>