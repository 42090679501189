<template>
	<div class="loading">
		<span v-html="title"></span>
		<div class="loading__anim"></div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: {
		title: {
			type: String,
			default: 'Načítám data&hellip;'
		}
	}
}
</script>
