<template>
  <section>
    <h3 class="section__title">INFORMACE O ORGANIZACI</h3>

    <Message v-if="error" type="error">
      {{error}}
    </Message>

    <Loading v-if="!dataLoaded" />

    <table v-if="dataLoaded && result.length !== 0" class="datagrid">
      <thead>
        <th>Dotační rok</th>
        <th>Dotační řízení</th>
        <th>Druh řízení</th>
        <th>Stav</th>
      </thead>
      <tbody>
        <tr
          v-for="info in result"
          :key="info.dotacni_rok"
          @click="edit(informace.dotacni_rok)"
        >
          <td>{{ info.dotacni_rok }}</td>
          <td>{{ info.dotacni_rok == 2022 ? 'Otevřené dotační řízení' : 'Uzavřeno' }}</td>
          <td>&nbsp;</td>
          <td>{{ info.stav_formulare_a ? info.stav_nazev : 'Nevyplněno' }}</td>
        </tr>
      </tbody>
    </table>

    <p v-if="dataLoaded && result.length === 0" >
      <table class="datagrid">
        <tbody>
          <tr>
            <td>Nejsou k dispozici žádná data.</td>
          </tr>
        </tbody>
      </table>
    </p>

  </section>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import InformaceOrganizaceService from '@/services/InformaceOrganizaceService'

import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'InformaceOrganizacePrehled',

  components: {
    Message,
    Loading,
  },

  data() {
    return {
      error: '',
      informace: [],
      dataLoaded: false,
    }
  },

  computed: {
		...authComputed,

    result() {
      return this.informace
    },
  },

  methods: {
    edit(dotacniRok) {
      this.$router.push({ name: 'OrganizaceInformace', params: { dotacniRok: dotacniRok } })
    },

    loadData() {
			InformaceOrganizaceService
      .getInformaceOrganizacePrehled(this.userOrg.idOrganizace)
			.then(response => {
				if (response.data.success) {
					this.informace = response.data.data
          this.dataLoaded = true
				} else {
          this.error += response.data.error
				}
			})
			.catch(error => {
        this.error += error.message
			})
    }
  },

  created() {
    this.loadData()
  }
}
</script>
