<template>
	<PageHeader :title="'Žádost o dotaci (část B)' + nazevProjektu" />

	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<div class="content">

		<Message type="error" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">
			<CastB1
				v-if="tabSelected === 1"
				:projekt="projekt"
			/>

			<CastB2Sluzba
				v-if="tabSelected === 2 & projekt.typ == 1"
				:projekt="projekt"
			/>

			<CastB2Projekt
				v-if="tabSelected === 2 & projekt.typ == 2"
				:projekt="projekt"
			/>
		</template>

	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import CastB1 from '@/components/Projekty/CastB1'
import CastB2Sluzba from '@/components/Projekty/CastB2Sluzba'
import CastB2Projekt from '@/components/Projekty/CastB2Projekt'

export default {
	name: 'Projekt-B',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		CastB1,
		CastB2Sluzba,
		CastB2Projekt,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
		zalozka: {
			type: Number,
			default: 1,
		},
	},

	data() {
		return {
			error: '',
			dataLoaded: false,

			projekt: {},

			tabSelected: 1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Žádost o dotaci',
					subtitle: 'Část B1',
				},
				{
					id: 2,
					title: 'Charakteristika a popis služby/projektu',
					subtitle: 'Část B2',
				}
			]
		}
	},

	computed: {
		...authComputed,

		nazevProjektu() {
			return (
				this.dataLoaded
				? ` - ${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},
	},

	watch: {
		zalozka(newVal, oldVal) {
			this.tabSelected = newVal
		},
	},

	methods: {

		changeTab(tabId) {
			switch (tabId) {
				case 0:
					// zpět na projekt
					this.$router.push({ name: 'Projekt', params: { id: this.id } })
					break
				case 1:
					// B1
					this.$router.push({ name: 'Projekt-B1', params: { id: this.id } })
					break
				case 2:
					// B2
					this.$router.push({ name: 'Projekt-B2', params: { id: this.id } })
					break
			}
		},

		loadData() {
			ProjektyService
			.getProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projekt = response.data.data.projekt
					this.b1 = response.data.data.b1
					this.b2 = response.data.data.b2
					this.dataLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

	},


	created() {
		this.tabSelected = this.zalozka;
		this.loadData()
	},

}
</script>
