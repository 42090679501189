import ApiClient from './ApiClient'

export default {

	getOrganizace() {
		return ApiClient.get('/organizace')
	},

	getOrganizaceDetail(id) {
		return ApiClient.get(`/organizace/${id}`)
	},

	createOrganizace(user) {
		return ApiClient
			.post('/uzivatele', user)
			// organizace se založí vytvořením
			// statutárního zástupce v uživatelích
	},

}
