import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import Login from '../views/Login'
import Logout from '../views/Logout'
import Dashboard from '../views/Dashboard'
import Organizace from '../views/Organizace'
import Uzivatele from '../views/Uzivatele'
import OrganizaceInformace from '../views/OrganizaceInformace'
import OrganizaceKontrola from '../views/OrganizaceKontrola'
import DotacniRoky from '../views/DotacniRoky'
import DotacniRokEdit from '../views/DotacniRokEdit'
import Zadost from '../views/Zadost'
import ZadostAdmin from '../views/ZadostAdmin'
import ProjektNovy from '../views/ProjektNovy'
import Projekt from '../views/Projekt'
import ProjektC from '../views/Projekt-C'
import ProjektB from '../views/Projekt-B'
import Zadosti from '../views/Zadosti'
import Projekty from '../views/Projekty'
import Exporty from '../views/Exporty'
import Hodnoceni from '../views/Hodnoceni'


const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/organizace',
    name: 'Organizace',
    component: Organizace,
    meta: { requiresLogin: true },
  },
  {
    path: '/uzivatele',
    name: 'Uzivatele',
    component: Uzivatele,
    meta: { requiresLogin: true },
  },
  {
    path: '/organizace-informace',
    name: 'OrganizaceInformace',
    component: OrganizaceInformace,
    meta: { requiresLogin: true },
  },
  {
    path: '/organizace-kontrola/:id',
    name: 'OrganizaceKontrola',
    component: OrganizaceKontrola,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/dotacni-roky',
    name: 'DotacniRoky',
    component: DotacniRoky,
    meta: { requiresLogin: true },
  },
  {
    path: '/dotacni-rok-novy',
    name: 'DotacniRokNovy',
    component: DotacniRokEdit,
    meta: { requiresLogin: true },
  },
  {
    path: '/dotacni-rok-edit/:rok',
    name: 'DotacniRokEdit',
    component: DotacniRokEdit,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/zadost/',
    name: 'Zadost',
    component: Zadost,
    meta: { requiresLogin: true },
  },
  {
    path: '/zadost/:id',
    name: 'ZadostAdmin',
    component: ZadostAdmin,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt-novy/:idZadosti',
    name: 'ProjektNovy',
    component: ProjektNovy,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id',
    name: 'Projekt',
    component: Projekt,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/c',
    name: 'Projekt-C',
    component: ProjektC,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/b1',
    name: 'Projekt-B1',
    component: ProjektB,
    props: route => ({ id: route.params.id, zalozka: 1 }),
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/b2',
    name: 'Projekt-B2',
    component: ProjektB,
    props: route => ({ id: route.params.id, zalozka: 2 }),
    meta: { requiresLogin: true },
  },
  {
    path: '/zadosti',
    name: 'Zadosti',
    component: Zadosti,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekty',
    name: 'Projekty',
    component: Projekty,
    meta: { requiresLogin: true },
  },
  {
    path: '/exporty',
    name: 'Exporty',
    component: Exporty,
    meta: { requiresLogin: true },
  },
  {
    path: '/hodnoceni',
    name: 'Hodnoceni',
    component: Hodnoceni,
    meta: { requiresLogin: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  // cesta nevyzaduje login? dovolime navigaci
  if (!to.matched.some(record => record.meta?.requiresLogin)) return true

  // uzivatel je prihlaseny? dovolime navigaci
  if (store.getters.isAuthenticated) return true

  // cesta vyzaduje navigaci a uzivatel neni prihlaseny?
  // nacteme data ze sessionStorage
  const user = sessionStorage.getItem('c_user')
  if (user) {
    // v sessionStorage je uložený uživatel z posledního přihlášení
    // načteme data
    store.commit('SET_USER_DATA', JSON.parse(user))

    // a spustíme akci pro načtení číselníků
    // ...

    // a dovolime navigaci
    return true
  }

  // nepodařilo se načíst uživatele ze sessionStorage
  // přesměrujeme na Login
  return { name: 'Login' }
})


export default router
