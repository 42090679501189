<template>
	<PageHeader title="Exporty" />

	<Message type="error" v-if="error">
		{{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

	<template v-if="dataLoaded">
		<div class="panelgrid mt-5">
			<ExportInformaceOrganizace :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<ExportProjektoveZadosti :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
		</div>

		<h2 class="section__title mt-5">Hromadné exporty</h2>

		<div class="panelgrid">
			<HromadnyExportProjekty :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportCastiA :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportCastiC :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportPrilohyZadosti :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
		</div>
	</template>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ExportyService from '@/services/ExportyService'

import { saveAs } from 'file-saver'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

import ExportProjektoveZadosti from '@/components/Exporty/ExportProjektoveZadosti'
import ExportInformaceOrganizace from '@/components/Exporty/ExportInformaceOrganizace'
import HromadnyExportProjekty from '@/components/Exporty/HromadnyExportProjekty'
import HromadnyExportCastiA from '@/components/Exporty/HromadnyExportCastiA'
import HromadnyExportCastiC from '@/components/Exporty/HromadnyExportCastiC'
import HromadnyExportPrilohyZadosti from '@/components/Exporty/HromadnyExportPrilohyZadosti'

export default {
	name: 'Exporty',

	components: {
		PageHeader,
		Message,
		Loading,
		ExportProjektoveZadosti,
		ExportInformaceOrganizace,
		HromadnyExportProjekty,
		HromadnyExportCastiA,
		HromadnyExportCastiC,
		HromadnyExportPrilohyZadosti,
	},

	data() {
		return {
			error: '',
			dataLoaded: false,
			dotacniRoky: [],
			aktualniRok: null,
		}
	},


	computed: {
		...authComputed,

	},


	methods: {

		loadData() {
			this.dataLoaded = false

			// FAKE, NAHRADIT OPRAVDOVYM API
			this.dotacniRoky = [2022]
			this.aktualniRok = 2022

			this.dataLoaded = true
		},

	},


	created() {
		this.loadData()
	},

}
</script>
