<template>
	<Tabs
		:items="tabItems"
		:selected="tabSelected"
	/>

	<div class="content" ref="content">

		<Message v-if="error" type="error" class="mb-4">
      {{error}}
    </Message>

		<Message v-if="message" type="info" class="mb-4">
      {{message}}
    </Message>

		<Loading v-if="!dataLoaded" />
		<form
			novalidate
			v-if="dataLoaded"
			@submit.prevent=""
		>

			<div class="form-grid">
				<div class="form-grid__column form-grid__column--full">
					<FormInput v-model="formular.nazev" v-bind="fieldAttrs('nazev')" />
				</div>

				<div class="form-grid__column">
					<FormSelect
						class="form__field--max350"
						v-model="formular.id_pravni_forma"
						v-bind="fieldAttrs('id_pravni_forma')"
						:options="pravniFormy"
						valueKey="id"
						optionKey="pravni_forma"
					/>
				</div>
			</div>

			<hr class="form__divider">

			<div class="form-grid">
				<div class="form-grid__column">
					<h3 class="form__section-title">ADRESA SÍDLA ORGANIZACE</h3>

					<FormInput v-model="formular.sidlo_obec" v-bind="fieldAttrs('sidlo_obec')" />
					<FormInput v-model="formular.sidlo_kod_obce" v-bind="fieldAttrs('sidlo_kod_obce')" />
					<div class="form__field">
						<div class="form__label">&nbsp;</div>
						<div class="form_input"><a href="https://www.czso.cz/csu/czso/ciselnik_obci_-cisob-" target="_blank">Seznam kódů obcí</a> na webu Českého statistického úřadu.</div>
					</div>

					<FormInput v-model="formular.sidlo_psc" v-bind="fieldAttrs('sidlo_psc')" />
					<FormInput v-model="formular.sidlo_cast_obce" v-bind="fieldAttrs('sidlo_cast_obce')" />

					<FormSelect
						v-model="formular.sidlo_id_kraj"
						v-bind="fieldAttrs('sidlo_id_kraj')"
						:options="kraje"
						valueKey="id"
						optionKey="kraj"
						emptyValueText="- vyberte kraj -"
					/>

					<FormSelect
						v-model="formular.sidlo_id_okres"
						v-bind="fieldAttrs('sidlo_id_okres')"
						:options="okresySidlo"
						valueKey="id"
						optionKey="okres"
						emptyValueText="- vyberte okres -"
					/>

					<FormInput v-model="formular.sidlo_ulice" v-bind="fieldAttrs('sidlo_ulice')" />
					<FormInput v-model="formular.sidlo_cp" v-bind="fieldAttrs('sidlo_cp')" />
					<FormInput v-model="formular.sidlo_co" v-bind="fieldAttrs('sidlo_co')" />
					<FormInput v-model="formular.sidlo_telefon" v-bind="fieldAttrs('sidlo_telefon')" />
					<FormInput v-model="formular.sidlo_www" v-bind="fieldAttrs('sidlo_www')" />
				</div>
				<div class="form-grid__column">
					<h3 class="form__section-title">STATUTÁRNÍ ORGÁN</h3>

					<FormInput v-model="formular.statutar_typ" v-bind="fieldAttrs('statutar_typ')" />
					<FormInput v-model="formular.statutar_funkce" v-bind="fieldAttrs('statutar_funkce')" />
					<FormInput v-model="formular.statutar_jmeno" v-bind="fieldAttrs('statutar_jmeno')" />
					<FormInput v-model="formular.statutar_prijmeni" v-bind="fieldAttrs('statutar_prijmeni')" />
					<FormInput v-model="formular.statutar_titul" v-bind="fieldAttrs('statutar_titul')" />
					<FormInput v-model="formular.statutar_telefon" v-bind="fieldAttrs('statutar_telefon')" />
					<FormInput v-model="formular.statutar_mobil" v-bind="fieldAttrs('statutar_mobil')" />
					<FormInput v-model="formular.statutar_email" v-bind="fieldAttrs('statutar_email')" />

					<div class="dorucovani-info">
						<div class="form__info">V souladu s §19 odst. 4 správního řádu žádám o doručování písemností v rámci dotačního řízení ÚV ČR na protidrogovou politiku na níže uvedené e-mailové adresy:</div>
					</div>

					<FormInput v-model="formular.dorucovani_email1" v-bind="fieldAttrs('dorucovani_email1')" />
					<FormInput v-model="formular.dorucovani_email2" v-bind="fieldAttrs('dorucovani_email2')" />
				</div>
			</div>

			<hr class="form__divider">

			<div class="form-grid">
				<div class="form-grid__column">
					<h3 class="form__section-title">Další údaje o organizaci</h3>

					<FormInput v-model="formular.cislo_uctu" v-bind="fieldAttrs('cislo_uctu')"	/>
					<FormInput v-model="formular.cislo_registrace"	v-bind="fieldAttrs('cislo_registrace')"	/>
					<FormInput v-model="formular.ic" v-bind="fieldAttrs('ic')" />
					<FormInput v-model="formular.dic" v-bind="fieldAttrs('dic')" />
					<FormInput v-model="formular.datova_schranka" v-bind="fieldAttrs('datova_schranka')" />

					<h3 class="form__section-title mt-5">DOKLADY O PŘEDMĚTU ČINNOSTI A BANKOVNÍM SPOJENÍ</h3>
					<FormFile
						v-bind="fieldAttrs('id_doklad_predmet_cinnosti')"
						:file="fileInfo(formular.id_doklad_predmet_cinnosti)"
						:idZadosti="zadost.id"
					/>
					<FormFile
						v-bind="fieldAttrs('id_doklad_bankovni_spojeni')"
						:file="fileInfo(formular.id_doklad_bankovni_spojeni)"
						:idZadosti="zadost.id"
					/>

				</div>
				<div class="form-grid__column">
					<h3 class="form__section-title">OSOBA POVĚŘENÁ JEDNAT JMÉNEM STATUTÁRNÍHO ORGÁNU</h3>

					<FormCheckbox
						v-model="formular.jednatel_zadat"
						v-bind="fieldAttrs('jednatel_zadat')"
					/>

					<template v-if="formular.jednatel_zadat">
						<FormInput v-model="formular.jednatel_jmeno" v-bind="fieldAttrs('jednatel_jmeno')" />
						<FormInput v-model="formular.jednatel_prijmeni" v-bind="fieldAttrs('jednatel_prijmeni')" />
						<FormInput v-model="formular.jednatel_titul" v-bind="fieldAttrs('jednatel_titul')" />
						<FormInput v-model="formular.jednatel_funkce" v-bind="fieldAttrs('jednatel_funkce')" />
						<FormInput v-model="formular.jednatel_telefon" v-bind="fieldAttrs('jednatel_telefon')" />
						<FormInput v-model="formular.jednatel_mobil" v-bind="fieldAttrs('jednatel_mobil')" />
						<FormInput v-model="formular.jednatel_email" v-bind="fieldAttrs('jednatel_email')" />

						<h3 class="form__section-title mt-5">POVĚŘENÍ JEDNAT JMÉNEM STATUTÁRNÍHO ORGÁNU</h3>
						<FormFile
							v-bind="fieldAttrs('jednatel_id_povereni')"
							:file="fileInfo(formular.jednatel_id_povereni)"
							:idZadosti="zadost.id"
						/>
					</template>
				</div>
			</div>

			<hr class="form__divider">

			<div class="form-grid">
				<div class="form-grid__column">
					<h3 class="form__section-title">KONTAKTNÍ ADRESA ORGANIZACE (je-li jiná, než adresa sídla)</h3>

					<FormCheckbox
						v-model="formular.kontakt_zadat"
						v-bind="fieldAttrs('kontakt_zadat')"
					/>

					<template v-if="formular.kontakt_zadat">
						<FormInput v-model="formular.kontakt_obec" v-bind="fieldAttrs('kontakt_obec')" />
						<FormInput v-model="formular.kontakt_kod_obce" v-bind="fieldAttrs('kontakt_kod_obce')" />
						<FormInput v-model="formular.kontakt_psc" v-bind="fieldAttrs('kontakt_psc')" />
						<FormInput v-model="formular.kontakt_cast_obce" v-bind="fieldAttrs('kontakt_cast_obce')" />

						<FormSelect
							v-model="formular.kontakt_id_kraj"
							v-bind="fieldAttrs('kontakt_id_kraj')"
							:options="kraje"
							valueKey="id"
							optionKey="kraj"
							emptyValueText="- vyberte kraj -"
						/>

						<FormSelect
							v-model="formular.kontakt_id_okres"
							v-bind="fieldAttrs('kontakt_id_okres')"
							:options="okresyKontakt"
							valueKey="id"
							optionKey="okres"
							emptyValueText="- vyberte okres -"
						/>

						<FormInput v-model="formular.kontakt_ulice" v-bind="fieldAttrs('kontakt_ulice')" />
						<FormInput v-model="formular.kontakt_cp" v-bind="fieldAttrs('kontakt_cp')" />
						<FormInput v-model="formular.kontakt_co" v-bind="fieldAttrs('kontakt_co')" />
						<FormInput v-model="formular.kontakt_telefon" v-bind="fieldAttrs('kontakt_telefon')" />
						<FormInput v-model="formular.kontakt_www" v-bind="fieldAttrs('kontakt_www')" />
					</template>
				</div>
				<div class="form-grid__column">
					<h3 class="form__section-title">KONTAKTNÍ OSOBA ZODPOVĚDNÁ ZA ROZPOČTOVOU ČÁST</h3>

					<FormCheckbox
						v-model="formular.rozpoctar_zadat"
						v-bind="fieldAttrs('rozpoctar_zadat')"
					/>

					<template v-if="formular.rozpoctar_zadat">
						<FormInput v-model="formular.rozpoctar_jmeno" v-bind="fieldAttrs('rozpoctar_jmeno')" />
						<FormInput v-model="formular.rozpoctar_prijmeni" v-bind="fieldAttrs('rozpoctar_prijmeni')" />
						<FormInput v-model="formular.rozpoctar_titul" v-bind="fieldAttrs('rozpoctar_titul')" />
						<FormInput v-model="formular.rozpoctar_funkce" v-bind="fieldAttrs('rozpoctar_funkce')" />
						<FormInput v-model="formular.rozpoctar_telefon" v-bind="fieldAttrs('rozpoctar_telefon')" />
						<FormInput v-model="formular.rozpoctar_mobil" v-bind="fieldAttrs('rozpoctar_mobil')" />
						<FormInput v-model="formular.rozpoctar_email" v-bind="fieldAttrs('rozpoctar_email')" />
					</template>
				</div>
			</div>

			<hr class="form__divider">

			<div class="form-grid">
				<div class="form-grid__column">
					<h3 class="form__section-title">ÚZEMNÍ PŮSOBNOST ŽADATELE <span class="required">*</span></h3>

					<FormCheckboxArray
						v-model="formular.pusobnost_id_kraj"
						v-bind="fieldAttrs('pusobnost_id_kraj')"
						:options="kraje"
						valueKey="id"
						labelKey="kraj"
						:label="null"
						:allTools="true"
						:columns="2"
					/>

					<h3 class="form__section-title mt-5">STRUČNÉ PŘEDSTAVENÍ ORGANIZACE <span class="required">*</span></h3>

					<FormTextarea v-model="formular.predstaveni_organizace" v-bind="fieldAttrs('predstaveni_organizace')" :rows="8" />

				</div>
				<div class="form-grid__column">

					<h3 class="form__section-title">PŘEHLED MAJETKOVÝCH VZTAHŮ K JINÝM OSOBÁM</h3>

					<Message type="info" class="mb-4">
						Přehled majektových vztahů je pevnou součástí Čestného prohlášení, které je složkou generovaného formuláře&nbsp;A žádosti.
					</Message>

					<FormTextarea v-model="formular.majetek_maji_podil" v-bind="fieldAttrs('majetek_maji_podil')" />
					<FormTextarea v-model="formular.majetek_mame_podil" v-bind="fieldAttrs('majetek_mame_podil')" />
					<FormTextarea v-model="formular.majetek_maji_prospech" v-bind="fieldAttrs('majetek_maji_prospech')" />

				</div>
			</div>

			<hr class="form__divider">

			<Loading title="Ukládám data&hellip;" v-if="isSaving" />

			<Message v-if="!isAllChecked" type="error" class="mb-4">
				Formulář nelze ani schválit ani vrátit k přepracování, dokud neoznačíte všechna pole jako správně nebo špatně vyplněná.
			</Message>

			<div class="form__buttons">
				<button
					class="button button--success"
					:disabled="!canApprove"
					@click.prevent="approveStatus"

				>
					<font-awesome-icon icon="check" class="button__icon" />
					Schválit informace
				</button>

				<button
					class="button button--danger "
					:disabled="!canReturn"
					@click.prevent="returnStatus"
				>
					<font-awesome-icon icon="undo" class="button__icon" />
					Vrátit k přepracování
				</button>

			</div>

		</form>

	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import InformaceOrganizaceService from '@/services/InformaceOrganizaceService.js'
import CiselnikyService from '@/services/CiselnikyService.js'

import Loading from '@/components/Common/Loading'
import Message from '@/components/Common/Message'
import Tabs from '@/components/Common/Tabs'
import FormInput from '@/components/Forms/FormInput'
import FormTextarea from '@/components/Forms/FormTextarea'
import FormSelect from '@/components/Forms/FormSelect'
import FormCheckbox from '@/components/Forms/FormCheckbox'
import FormCheckboxArray from '@/components/Forms/FormCheckboxArray'
import FormFile from '@/components/Forms/FormFile'

export default {
	name: 'FormularAKontrola',

	components: {
		Loading,
		Message,
		Tabs,
		FormInput,
		FormTextarea,
		FormSelect,
		FormCheckbox,
		FormCheckboxArray,
		FormFile,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			error: '',
			error2: '',
			message: '',
			isSaving: false,
			zadostLoaded: false,
			krajeLoaded: false,
			okresyLoaded: false,
			pravniFormyLoaded: false,
			stavyFormulareALoaded: false,
			formChanged: false,
			zadost: null,
			formular: null,
			historie: null,
			kontrola: null,
			soubory: null,
			pravniFormy: [],
			kraje: [],
			okresy: [],
			stavyFormulareA: [],

			tabItems: [],
			tabSelected: 2022,

			schema: {
				nazev: { label: 'Úplný název žadatele', required: true, },
				id_pravni_forma: { label: 'Právní forma', required: true, },
				sidlo_obec: { label: 'Obec', label2: 'Sídlo organizace', required: true, },
				sidlo_kod_obce: { label: 'Kód obce', label2: 'Sídlo organizace', required: true, validator: 'int', },
				sidlo_psc: { label: 'PSČ', label2: 'Sídlo organizace', required: true, },
				sidlo_cast_obce: { label: 'Část obce', label2: 'Sídlo organizace', required: false, },
				sidlo_id_kraj: { label: 'Kraj', label2: 'Sídlo organizace', required: true, },
				sidlo_id_okres: { label: 'Okres', label2: 'Sídlo organizace', required: true, },
				sidlo_ulice: { label: 'Ulice', label2: 'Sídlo organizace', required: true, },
				sidlo_cp: { label: 'Č.p.', label2: 'Sídlo organizace', required: true, },
				sidlo_co: { label: 'Č.o.', label2: 'Sídlo organizace', required: false, },
				sidlo_telefon: { label: 'Telefon', label2: 'Sídlo organizace', required: true, },
				sidlo_www: { label: 'WWW', label2: 'Sídlo organizace', required: false, validator: 'url', },
				statutar_typ: { label: 'Kdo/co je statutární orgán', required: true, help: 'Uveďte formu – např. rada, představenstvo, ředitel…' },
				statutar_funkce: { label: 'Kdo jedná jménem právnické osoby', required: true, help: 'Uveďte pozici – např. předseda, jednatel, ředitel…' },
				statutar_jmeno: { label: 'Jméno jednatele / statutárního zástupce', required: true, },
				statutar_prijmeni: { label: 'Příjmení jednatele / statutárního zástupce', required: true, },
				statutar_titul: { label: 'Titul', label2: 'Statutární zástupce', required: false, },
				statutar_telefon: { label: 'Telefon', label2: 'Statutární zástupce', required: true, },
				statutar_mobil: { label: 'Mobil', label2: 'Statutární zástupce', required: true, },
				statutar_email: { label: 'E-mail', label2: 'Statutární zástupce', required: true, validator: 'email', },
				dorucovani_email1: { label: 'E-mail 1', label2: 'Doručování písemností', required: true, validator: 'email', },
				dorucovani_email2: { label: 'E-mail 2', label2: 'Doručování písemností', required: true, validator: 'email', },
				cislo_uctu: { label: 'Číslo bankovního účtu', required: true, help: 'Uveďte ve formátu 000000-0000000000/0000 nebo 0000000000/0000.'},
				cislo_registrace: { label: 'Číslo registrace', required: true, help: 'Uveďte číslo, kterým organizaci registroval příslušný rejtříkový soud, např. U 425 vedená u městského soudu v Praze.' },
				ic: { label: 'IČ', required: true, },
				dic: { label: 'DIČ', required: false, },
				datova_schranka: { label: 'Datová schránka', required: false, },
				id_doklad_predmet_cinnosti: { label: 'Doklad o předmětu činnosti', required: true, help: "Jedná se např. o Stanovy, Zřizovací listinu, Výpis z veřejného rejstříku, Zřizovací dekret, Statut a další."},
				id_doklad_bankovni_spojeni: { label: 'Doklad o bankovním spojení', required: true, },
				kontakt_zadat: { label: 'Zadat kontaktní adresu', required: false, },
				kontakt_obec: { label: 'Obec', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_kod_obce: { label: 'Kód obce', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', validator: 'int', },
				kontakt_psc: { label: 'PSČ', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_cast_obce: { label: 'Část obce', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_id_kraj: { label: 'Kraj', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_id_okres: { label: 'Okres', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_ulice: { label: 'Ulice', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_cp: { label: 'Č.p.', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_co: { label: 'Č.o.', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_telefon: { label: 'Telefon', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', },
				kontakt_www: { label: 'WWW', label2: 'Kontaktní adresa', required: false, dependsOn: 'kontakt_zadat', validator: 'url', },
				jednatel_zadat: { label: 'Zadat pověřenou osobu', required: false, },
				jednatel_jmeno: { label: 'Jméno', label2: 'Osoba pověřená jednáním', required: true, dependsOn: 'jednatel_zadat', },
				jednatel_prijmeni: { label: 'Příjmení', label2: 'Osoba pověřená jednáním', required: true, dependsOn: 'jednatel_zadat', },
				jednatel_titul: { label: 'Titul', label2: 'Osoba pověřená jednáním', required: false, dependsOn: 'jednatel_zadat', },
				jednatel_funkce: { label: 'Funkce', label2: 'Osoba pověřená jednáním', required: true, dependsOn: 'jednatel_zadat', },
				jednatel_telefon: { label: 'Telefon', label2: 'Osoba pověřená jednáním', required: true, dependsOn: 'jednatel_zadat', },
				jednatel_mobil: { label: 'Mobil', label2: 'Osoba pověřená jednáním', required: true, dependsOn: 'jednatel_zadat', },
				jednatel_email: { label: 'E-mail', label2: 'Osoba pověřená jednáním', required: true, dependsOn: 'jednatel_zadat', validator: 'email', },
				jednatel_id_povereni: { label: 'Pověření jednat jménem statutárního orgánu', required: true, dependsOn: 'jednatel_zadat', },
				rozpoctar_zadat: { label: 'Zadat kontaktní osobu', required: false, },
				rozpoctar_jmeno: { label: 'Jméno', label2: 'Osoba zodpovědná za rozpočet', required: true, dependsOn: 'rozpoctar_zadat', },
				rozpoctar_prijmeni: { label: 'Příjmení', label2: 'Osoba zodpovědná za rozpočet', required: true, dependsOn: 'rozpoctar_zadat', },
				rozpoctar_titul: { label: 'Titul', label2: 'Osoba zodpovědná za rozpočet', required: false, dependsOn: 'rozpoctar_zadat', },
				rozpoctar_funkce: { label: 'Funkce', label2: 'Osoba zodpovědná za rozpočet', required: true, dependsOn: 'rozpoctar_zadat', },
				rozpoctar_telefon: { label: 'Telefon', label2: 'Osoba zodpovědná za rozpočet', required: true, dependsOn: 'rozpoctar_zadat', },
				rozpoctar_mobil: { label: 'Mobil', label2: 'Osoba zodpovědná za rozpočet', required: true, dependsOn: 'rozpoctar_zadat', },
				rozpoctar_email: { label: 'E-mail', label2: 'Osoba zodpovědná za rozpočet', required: true, dependsOn: 'rozpoctar_zadat', validator: 'email', },
				pusobnost_id_kraj: { label: 'Kraj', label2: 'Územní působnost žadatele', required: true, },
				predstaveni_organizace: { label: '', labelShort: 'Stručné představení organizace', required: true, },
				majetek_maji_podil: { label: 'Osoby s podílem v organizaci', required: true, },
				majetek_mame_podil: { label: 'Osoby, v nichž má organizace podíl, a výše tohoto podílu', required: true, },
				majetek_maji_prospech: { label: 'Osoby, které jsou s žadatelem o dotaci v obchodním vztahu a mají z jeho podnikání nebo jiné výdělečné činnosti prospěch, který se liší od prospěchu, který byl získán mezi nezávislými osobami v běžných obchodních vztazích za stejných nebo obdobných podmínek', labelShort: 'Osoby, které jsou s žadatelem o dotaci v obchodním vztahu', required: true, },
			},
		}
	},

  computed: {
    ...authComputed,

		dataLoaded() {
			return this.zadostLoaded && this.krajeLoaded && this.okresyLoaded && this.pravniFormyLoaded && this.stavyFormulareALoaded
		},

		okresySidlo() {
			return this.formular?.sidlo_id_kraj
				? this.okresy.filter(okres => okres.id_kraj == this.formular.sidlo_id_kraj)
				: []
		},

		okresyKontakt() {
			return this.formular?.kontakt_id_kraj
				? this.okresy.filter(okres => okres.id_kraj == this.formular.kontakt_id_kraj)
				: []
		},

		isAllApproved() {
			return Object.keys(this.kontrola).every(field =>
				this.kontrola[field] === 1
				|| (this.schema[field]?.dependsOn && !this.formular[this.schema[field].dependsOn])
			)
		},

		isAllChecked() {
			return Object.keys(this.kontrola).every(field =>
				this.kontrola[field] === 1
				|| this.kontrola[field] === 2
				|| (this.schema[field]?.dependsOn && !this.formular[this.schema[field].dependsOn])

			)
		},

		canApprove() {
			// formular jde schvalit pouze v pripade, ze:
			// - vsechna pole jsou oznacena jako schvalena
			// - a k tomu:
			//   - formular ceka na schvaleni (stav 2)
			//   - nebo je v jinem stavu (napr. je uz schvaleny), ale doslo v nem ke zmene
			return this.isAllApproved && (this.formChanged || this.zadost.stav_formulare_a === 2)
		},

		canReturn() {
			// formular jde vratit k prepracovani pouze v pripade, ze:
			// - vsechna pole jsou nejak oznacena
			// - alespon nektere pole je oznacene jako spatne
			// - a k tomu:
			//   - formular ceka na schvaleni (stav 2)
			//   - nebo je v jinem stavu (napr. je uz schvaleny), ale doslo v nem ke zmene
			return this.isAllChecked && !this.isAllApproved && (this.formChanged || this.zadost.stav_formulare_a === 2)
		},

  },

	methods: {

		approveStatus() {
			// schválení formuláře
			// musí být schválená všechna pole
			if (this.isAllApproved) {
				this.saveData(
					{
				 		draft: false,					// finalni verze dokumentu
						stav_formulare_a: 4		// schvaleno
					},
					'Informace o organizaci byly schváleny.'
				)
			}
		},

		returnStatus() {
			// vrácení formuláře k přepracování
			// všechna pole musí být nějak označená
			if (this.isAllChecked && !this.isAllApproved) {
				this.saveData(
					{
				 		draft: false,					// finalni verze dokumentu
						stav_formulare_a: 3		// vraceno k prepracovani
					},
					'Informace byly vráceny k přepracování.'
				)
			}
		},


		saveData(options, message = 'Formulář byl úspěšně uložen.') {
			const data = {
				...this.zadost,
				...this.formular,
				kontrola: this.kontrola,
				...options,
			}

			this.error = ''
			this.message = ''
			this.isSaving = true

			InformaceOrganizaceService
			.updateInformace(data)
			.then(response => {
				if (response.data.success) {
					this.message = message
				} else {
					this.error += response.data.error
										console.log(response.data)

				}
			})
			.catch(error => {
				this.error += error.message
									console.log(error.message)

			})
			.then(() => {
				// vyscrollujeme nahoru na strance, aby byla videt chyba nebo message
				this.isSaving = false
				window.scrollTo(0, 0)
			})
		},

		fileInfo(fileName) {
			return this.soubory.find(soubor => soubor.nazev === fileName)
		},

		updateStatus({field, status}) {
			this.kontrola[field] = status
			this.formChanged = true
		},

		fieldAttrs(name) {
			return {
				name: name,
				label: this.schema[name].label,
				required: this.schema[name].required,
				valid: undefined,
				disabled: true,
				help: this.schema[name]?.help,
				status: this.kontrola[name],
				statusOptions: {
					type: 'control',
					showAsEmpty: false,
					showApproved: true,
				},
				onUpdateStatus: this.updateStatus,
			}
		},

		createTabs() {
			this.tabItems.push({
				id: 2022,
				title: '2022',
				subtitle: this.stavyFormulareA.find(stav => stav.id == this.zadost.stav_formulare_a)?.stav,
			})
		},

		loadData() {
			InformaceOrganizaceService
			.getInformaceById(this.id)
			.then(response => {
				if (response.data.success) {
					this.zadost = {
						id: response.data.data.id,
						dotacni_rok: response.data.data.dotacni_rok,
						id_organizace: response.data.data.id_organizace,
						stav_formulare_a: response.data.data.stav_formulare_a,
						draft: response.data.data.draft,
					}
					this.formular = response.data.data.formular
					this.historie = response.data.data.historie
					this.kontrola = response.data.data.kontrola
					this.soubory = response.data.data.soubory
					this.zadostLoaded = true

					if (this.stavyFormulareALoaded) {
						this.createTabs()
					}
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
				this.error += error.message
			})

			CiselnikyService.getKraje()
			.then(response => {
				if (response.data.success) {
					this.kraje = response.data.data
					this.krajeLoaded = true
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
				this.error += error.message
			})

			CiselnikyService.getOkresy()
			.then(response => {
				if (response.data.success) {
					this.okresy = response.data.data
					this.okresyLoaded = true
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
				this.error += error.message
			})

			CiselnikyService.getPravniFormy()
			.then(response => {
				if (response.data.success) {
					this.pravniFormy = response.data.data
					this.pravniFormyLoaded = true
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
				this.error += error.message
			})

			CiselnikyService.getStavyFormulareA()
			.then(response => {
				if (response.data.success) {
					this.stavyFormulareA = response.data.data
					this.stavyFormulareALoaded = true

					if (this.zadostLoaded) {
						this.createTabs()
					}
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
				this.error += error.message
			})

    }
	},

	mounted() {
		this.loadData();
	}
}
</script>
