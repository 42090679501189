<template>
	<Dialog
		title="Zamknout žádost"
		class="dialog--danger"
	>
		<p><strong>Opravdu chcete žádost zamknout a odeslat ke schválení?</strong></p>
		<p>Po zamknutí již nepůjde žádost dále upravovat.</p>

		<hr class="form__divider">

		<div class="buttons">
			<button
				class="button button--danger"
				@click="$emit('confirm')"
			>
				<font-awesome-icon icon="lock" class="button__icon" />
				Ano, zamknout a odeslat
			</button>

			<button
				class="button button--neutral button--outline"
				@click="$emit('cancel')"
			>
				Storno
			</button>
		</div>

	</Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'

export default {
	name: 'ZadostUzamceniPotvrzeniDialog',

	components: {
		Dialog
	},

	emits: ['confirm', 'cancel'],

}
</script>
