<template>
  <div>
    <Modal
      v-if="editFormOpened"
      :overlay="true"
    >
      <StatutarEditForm
        :onSubmit="organizationUpdated"
        :onCancel="closeEditForm"
      />
    </Modal>

    <PageHeader title="Přehled organizací">
      <button
        class="button"
        @click="createOrganization"
      >
        <font-awesome-icon icon="plus" class="button__icon" />
        Přidat organizaci
      </button>
    </PageHeader>

    <Message type="info">
      Přehled organizací můžete filtrovat nebo řadit pomocí prvků v záhlaví tabulky. Najetím myši na zkrácený text v tabulce se zobrazí celý. Kliknutím kamkoliv na řádek v tabulkce se otevře dialogové okno s možností editace.
    </Message>

    <Message v-if="error" type="error">
      {{error}}
    </Message>

    <Loading v-if="!dataLoaded" />
    <table v-else class="datagrid mt-4">
      <thead>
        <tr>
          <th>
            Dotační rok
            <!-- <SortArrows field="prijmeni" :options="sortOptions" :sort="sortTable" /> -->
          </th>
          <th>
            Název organizace
            <!-- <SortArrows field="jmeno" :options="sortOptions" :sort="sortTable" /> -->
          </th>
          <th>
            Stav
            <!-- <SortArrows field="organizace" :options="sortOptions" :sort="sortTable" /> -->
          </th>
          <th>
            IČ
          </th>
          <th>
            DIČ
          </th>
          <th>
            Statutární zástupce
            <!-- <SortArrows field="prijmeni" :options="sortOptions" :sort="sortTable" /> -->
          </th>
          <th>Telefon</th>
          <th>E-mail</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="datagrid__row"
          v-for="org in organizaceResult"
          :key="org.id"
          :class="{ 'datagrid__row--non-clickable' : !org.stav_formulare_a || org.stav_formulare_a === 1 }"
          @click="editOrganization(org.id_zadosti, org.stav_formulare_a)"
        >
          <td>{{ org.dotacni_rok }}</td>
          <td>{{ org.organizace }}</td>
          <td>{{ org.stav_nazev || 'Nevyplněno' }}</td>
          <td>{{ org.ic }}</td>
          <td>{{ org.dic }}</td>
          <td>{{ org.statutar_jmeno + ' ' + org.statutar_prijmeni }}</td>
          <td>{{ org.statutar_telefon }}</td>
          <td>{{ org.statutar_email }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import OrganizaceService from '@/services/OrganizaceService'

import Modal from '@/components/Common/Modal'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import SortArrows from '@/components/DataGrid/SortArrows'
import StatutarEditForm from '@/components/Organizace/StatutarEditForm'

export default {
  name: 'Organizace',

  components: {
    Modal,
    PageHeader,
    Message,
    Loading,
    SortArrows,
    StatutarEditForm,
  },

  data() {
    return {
      error: '',
      organizace: [],
      dataLoaded: false,
      editFormOpened: false,
    }
  },


  computed: {
    organizaceResult() {
      return this.organizace
    }
  },

  methods: {
    closeEditForm() {
      this.editFormOpened = false
    },

    organizationUpdated() {
      this.closeEditForm()
      this.organizace = []
      this.dataLoaded = false
      this.loadData()
    },

    createOrganization() {
      this.editFormOpened = true
    },

    editOrganization(idZadosti, idStav) {
      if (idStav && idStav !== 1) {
        // presmerujeme na editaci formulare A,
        // ale jen pokud je formular alespon ve stavu "Odeslano ke kontrole" nebo dalsim
        this.$router.push({ name: 'OrganizaceKontrola', params: { id: idZadosti } })
      }
    },

    loadData() {
      OrganizaceService.getOrganizace()
        .then(response => {
          this.organizace = response.data.data
          this.dataLoaded = true
        })
        .catch(error => {
          this.error += error.response.data.error
        })
    }
  },

  created() {
    this.loadData()
  }
}
</script>
