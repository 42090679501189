<template>

	<Dialog title="Přihlášení">

		<form class="form form--size20">

			<Message
				class="mb-3"
				type="error"
				v-if="error"
			>
				{{ error }}
			</Message>

			<div class="form__field form__field--required">
				<label for="email" class="form__label">E-mail</label>
				<input
					class="form__input"
					type="text"
					name="email"
					id="email"
					v-model="email"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="password" class="form__label">Heslo</label>
				<input
					class="form__input"
					type="password"
					name="password"
					id="password"
					v-model="password"
				>
			</div>

			<div class="form__buttons form__buttons--aligned">

				<button
					class="button"
					@click.prevent="login"
				>
					<font-awesome-icon icon="sign-in-alt" class="button__icon" />
					Přihlásit
				</button>

			</div>

		</form>

	</Dialog>

</template>

<script>
import LoginService from '@/services/LoginService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'

export default {
	name: 'LoginForm',

	components: {
		Dialog,
		Message,
	},

	data() {
		return {
			email: '',
			password: '',
			error: '',
		}
	},

	methods: {
		login() {
			LoginService.login({
				email: this.email,
				password: this.password
			})
			.then(response => {
				if (response.data.success) {
					this.$store.dispatch('login', response.data.data)
					.then(() => {
						this.$router.push({ name: 'Dashboard' })
					})
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.response.message || 'Při přihlášení došlo k chybě'
			})
		}
	}
}
</script>
