import ApiClient from './ApiClient'

export default {

	getProjekty() {
		return ApiClient.get(`/projekty/`)
	},

	getProjekt(id) {
		return ApiClient.get(`/projekty/${id}`)
	},

	updateProjekt(id, data) {
		return ApiClient.put(`/projekty/${id}`, data)
	},

	createProjekt(projekt) {
		return ApiClient.post(`/projekty/`, projekt)
	},

	getProjektB1(id) {
		return ApiClient.get(`/projekty-b1/${id}`)
	},

	updateProjektB1(id, data) {
		return ApiClient.put(`/projekty-b1/${id}`, data)
	},

	getProjektB2(id) {
		return ApiClient.get(`/projekty-b2/${id}`)
	},

	updateProjektB2(id, data) {
		return ApiClient.put(`/projekty-b2/${id}`, data)
	},

	downloadVzorC(id) {
		return ApiClient.get(
			'/projekty-c-download/' + id,
			{	responseType: 'blob' },
		)
	},

	uploadCastC(formData) {
		return ApiClient.post(
			'/projekty-c-import/',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}
		)
	},

	deleteProjekt(id) {
		return ApiClient.delete(`/projekty/${id}`)
	},

	vratitProjekt(id, data) {
		return ApiClient.put(`/projekt-vratit/${id}`, data)
	},

	exportPDF(id) {
		return ApiClient.get(
			`/export-pdf-projekt/${id}`,
			{	responseType: 'blob' }
		)
	},

	getHodnotitele() {
		return ApiClient.get(`/hodnotitele`)
	},

	nastavitDotacniVybor(data) {
		return ApiClient.put(`/projekty-dotacni-vybor`, data)
	},

	nastavitHodnotitele(data) {
		return ApiClient.put(`/projekty-hodnotitel`, data)
	},

	getHodnoceni(koordinatorId, dotacniRok) {
		return ApiClient.get(`/hodnoceni/${koordinatorId}/${dotacniRok}`)
	},

	updateHodnoceni(koordinatorId, dotacniRok, data) {
		return ApiClient.put(`/hodnoceni/${koordinatorId}/${dotacniRok}`, data)
	},


}
