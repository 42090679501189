<template>
  <div>
    <PageHeader title="Vypsané dotační roky - termíny činností">
      <button
        class="button"
        @click="createDotacniRok"
      >
        <font-awesome-icon icon="plus" class="button__icon" />
        Přidat dotační rok
      </button>
    </PageHeader>

    <Message type="info">
      Kliknutím na řádek můžete daný dotační rok editovat. Přehled nezobrazuje oblasti a jednotlivá dotační kola (případně dofinancování). Tyto údaje jsou vidět až v detailu roku.
    </Message>

    <Message v-if="error" type="error">
      {{error}}
    </Message>

    <Loading v-if="!dataLoaded" />
    <table v-else class="datagrid mt-4">
      <thead>
        <tr>
          <th>
            Dotační rok
          </th>
          <th>
            Přihlášení organizace<br>
            do dotačního roku
          </th>
          <th>
            Termín podávání<br>
            žádostí o dotaci
          </th>
          <th>
            Žádosti o změny<br>
            konečné podoby rozpočtů
          </th>
          <th>
            Žádosti o změny<br>
            podílu
          </th>
          <th>
            Termín předložení<br>
            vyúčtování dotace
          </th>
          <th>
            Termín předložení<br>
            průběžné zprávy
          </th>
          <th>
            Termín předložení<br>
            závěrečné zprávy
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="datagrid__row"
          v-for="rok in dotacniRokyResult"
          :key="rok.rok"
          @click="editDotacniRok(rok.rok)"
        >
          <td>{{ rok.rok }}</td>
          <td>{{ formatDateCZ(rok.prihlaseni_dotacni_rizeni, '-') }}</td>
          <td>{{ formatDateCZ(rok.podani_zadosti, '-') }}</td>
          <td>{{ formatDateCZ(rok.zadosti_o_zmeny_rozpoctu, '-') }}</td>
          <td>{{ formatDateCZ(rok.zadosti_o_zmeny_podilu, '-') }}</td>
          <td>{{ formatDateCZ(rok.predlozeni_vyuctovani_dotace, '-') }}</td>
          <td>{{ formatDateCZ(rok.predlozeni_prubezne_zpravy, '-') }}</td>
          <td>{{ formatDateCZ(rok.predlozeni_zaverecne_zpravy, '-') }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import { formatDateCZ } from '@/utils/format'

import DotacniRokyService from '@/services/DotacniRokyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'DotacniRoky',

  components: {
    PageHeader,
    Message,
    Loading,
  },

  data() {
    return {
      error: '',
      dataLoaded: false,
      dotacniRoky: [],
    }
  },


  computed: {
    dotacniRokyResult() {
      return this.dotacniRoky
    }
  },

  methods: {

    formatDateCZ: formatDateCZ,

    createDotacniRok() {
      this.$router.push({ name: 'DotacniRokNovy' })
    },

    editDotacniRok(rok) {
      this.$router.push({ name: 'DotacniRokEdit', params: { rok: rok } })
    },

    loadData() {
      DotacniRokyService
      .getDotacniRoky()
      .then(response => {
        if (response.data.success) {
          this.dotacniRoky = response.data.data
          this.dataLoaded = true
        } else {
          this.error += response.data.error
        }
      })
      .catch(error => {
        this.error += error.message
      })
    }
  },

  created() {
    this.loadData()
  },

}
</script>
