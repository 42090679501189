import ApiClient from './ApiClient'

export default {

	getUzivatele() {
		return ApiClient.get('/uzivatele')
	},

	getUzivateleOrganizace(id) {
		return ApiClient.get('/uzivatele/?id_organizace=' + id)
	},

	getUzivatel(id) {
		return ApiClient.get('/uzivatele/' + id)
	},

	getRole() {
		return ApiClient.get('/role')
	},

	createUzivatel(user) {
		return ApiClient
			.post('/uzivatele', user)
	},

	updateUzivatel(user) {
		return ApiClient
			.put('/uzivatele/' + user.id, user)
	},

}
