<template>

	<div
		class="dialog dialog--center"
	>
		<h2
			class="dialog__header"
			v-if="title"
		>
			{{ title }}
		</h2>
		<div
			class="dialog__content"
			:class="{
				'dialog__content--center': options?.centerContent
			}"
		>
			<slot />
		</div>
	</div>

</template>

<script>
export default {
	name: 'Dialog',

	props: {
		title: {
			type: String,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
	}

}
</script>
