import ApiClient from './ApiClient'

export default {

	getAll() {
		return ApiClient.get(`/ciselniky`)
	},

	getKraje() {
		return ApiClient.get(`/kraje`)
	},

	getOkresy() {
		return ApiClient.get(`/okresy`)
	},

	getPravniFormy() {
		return ApiClient.get(`/pravni-formy`)
	},

	getTypySluzby() {
		return ApiClient.get(`/typy-sluzby`)
	},

	getCilovkyUzivatelu() {
		return ApiClient.get(`/cilovky-uzivatelu`)
	},

	getCilovkyProgramu() {
		return ApiClient.get(`/cilovky-programu`)
	},

	getStavyFormulareA() {
		return ApiClient.get(`/stavy-formulare-a`)
	},

}
