<template>
	<PageHeader :title="`Žádost ${dotacniRok ? '- dotační rok ' + dotacniRok : ''}`" />

	<Tabs
		:items="tabItems"
		:selected="dotacniRok"
		@changeTab="changeTab"
	/>

	<section class="content">

		<Message v-if="errors.length !== 0" type="error">
			<ul>
				<li v-for="(error, index) in errors" :key="index">
					{{error}}
				</li>
			</ul>
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded && zadosti.length === 0">
			<p>Vyplňovat žádost a přidávat projekty lze až po vyplnění a schválení <router-link :to="{ name: 'OrganizaceInformace' }">informací o organizaci</router-link>.</p>
			<p>Jakmile budou informace o vaší organizaci schváleny, vraťte se na tuto stránku a pokračujte ve vyplňování žádosti.</p>
		</template>

		<template v-if="dataLoaded && zadosti.length !== 0">
			<Message type="info">
				Žádost je ve stavu <strong>{{ zadost.stav_nazev }}</strong>.
			</Message>

			<ZadostZadatelODotaci :organizace="informaceOrganizace" class="mt-4" />
		</template>

	</section>

	<ZadostStazeniPDF
		class="mt-5"
		v-if="dataLoaded && zadost.id_stav !== STAV_ZADOSTI_PODAVANI"
		:idZadosti="zadost.id"
	/>

	<template v-if="dataLoaded && zadosti.length !== 0">

		<ZadostProjekty
			class="mt-5"
			:idZadosti="zadost.id"
			:projekty="projekty"
			:disabled="zadost.id_stav !== STAV_ZADOSTI_PODAVANI || !jeMoznePodatZadost"
		/>

		<ZadostPriloha
			class="mt-5"
			:idZadosti="zadost.id"
			:file="priloha"
			:disabled="zadost.id_stav !== STAV_ZADOSTI_PODAVANI || !jeMoznePodatZadost"
			@uploaded="prilohaUploaded"
		/>

		<ZadostAkce
			v-if="!jeZadostUzamcena && jeMoznePodatZadost"
			class="mt-5"
			:idZadosti="zadost.id"
		/>

	</template>

</template>


<script>
import {
	STAV_ZADOSTI_PODAVANI,
	STAV_ZADOSTI_UZAMCENO,
	STAV_ZADOSTI_VRACENO,
	STAV_ZADOSTI_SCHVALENO,
	STAV_ZADOSTI_ZAMITNUTO,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ZadostiService from '@/services/ZadostiService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import ZadostZadatelODotaci from '@/components/Zadost/ZadostZadatelODotaci'
import ZadostStazeniPDF from '@/components/Zadost/ZadostStazeniPDF'
import ZadostProjekty from '@/components/Zadost/ZadostProjekty'
import ZadostPriloha from '@/components/Zadost/ZadostPriloha'
import ZadostAkce from '@/components/Zadost/ZadostAkce'

export default {
	name: 'Zadost',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		ZadostZadatelODotaci,
		ZadostStazeniPDF,
		ZadostProjekty,
		ZadostPriloha,
		ZadostAkce,
	},

	data() {
		return {
      errors: [],
			zadostiLoaded: false,
			zadostLoaded: false,

			zadosti: [],

			zadost: {},
			informaceOrganizace: {},
			projekty: [],

			tabItems: [],
			dotacniRok: null,
		}
	},

  computed: {
    ...authComputed,

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			return Date.now() < Date.parse('2021-11-11')
		},

		jeZadostUzamcena() {
			return (
				this.zadost.id_stav === STAV_ZADOSTI_UZAMCENO
				|| this.zadost.id_stav === STAV_ZADOSTI_SCHVALENO
				|| this.zadost.id_stav === STAV_ZADOSTI_ZAMITNUTO
			)
		},

		dataLoaded() {
			return this.zadostiLoaded && this.zadostLoaded
		},

		priloha() {
			return (
				this.zadost.id_priloha
				? {
					nazev: this.zadost.id_priloha,
					puvodni_nazev: this.zadost.priloha_puvodni_nazev,
				}
				: null
			)
		},
  },

	methods: {
		changeTab(id) {
			this.tabSelected = id;
		},

		prilohaUploaded(file) {
			this.zadost.id_priloha = file.nazev
			this.zadost.priloha_puvodni_nazev = file.puvodni_nazev
		},

		async loadZadosti() {
			const userOrg = this.userOrg

			if (!userOrg) {
				this.errors.push('Chybí ID organizace')
				return
			}

			try {
				const response = await ZadostiService.getZadostiOrganizace(userOrg.idOrganizace)
				if (response.data.success) {
					this.zadostiLoaded = true
					return response.data.data
				} else {
					this.errors.push(response.data.error)
					this.zadostiLoaded = true
					return []
				}
			}
			catch(error) {
				this.errors.push(error.message)
				return []
			}
		},

		async loadZadostById(id) {
			try {
				const response = await ZadostiService.getZadostById(id)
				if (response.data.success) {
					return response.data.data
				} else {
					this.errors.push(response.data.error)
					return {}
				}
			}
			catch(error) {
				this.errors.push(error.message)
				return {}
			}
		},

		async loadData() {
			this.zadosti = await this.loadZadosti()

			// přemapujeme žádosti organizace na záložky
			if (this.zadosti.length >= 1) {
				this.tabItems = this.zadosti.map(item => ({
					id: item.dotacni_rok,
					title: item.dotacni_rok,
					subtitle: item.stav_nazev,
				}))
				this.dotacniRok = this.tabItems[0].id
			}

			// existuje-li alespoň jedna žádost,
			// načteme detail té nejnovější v seznamu
			if (this.zadosti.length >= 1) {
				const data = await this.loadZadostById(this.zadosti[0].id)
				this.zadost = data?.zadost
				this.informaceOrganizace = data?.informace_organizace
				this.projekty = data?.projekty
			}
			this.zadostLoaded = true
		}

	},

	created() {
		// constants
		this.STAV_ZADOSTI_PODAVANI  = STAV_ZADOSTI_PODAVANI
		this.STAV_ZADOSTI_UZAMCENO  = STAV_ZADOSTI_UZAMCENO
		this.STAV_ZADOSTI_VRACENO   = STAV_ZADOSTI_VRACENO
		this.STAV_ZADOSTI_SCHVALENO = STAV_ZADOSTI_SCHVALENO
		this.STAV_ZADOSTI_ZAMITNUTO = STAV_ZADOSTI_ZAMITNUTO

		this.loadData()
	}
}
</script>
