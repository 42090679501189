<template>
	<div
		class="form__field form__field--vertical"
		:class="{
			'form__field--required': required,
			'form__field--invalid': valid === false,
		}"
	>
		<div class="form__field-line" v-if="label">
			<font-awesome-icon
				class="form__help"
				icon="info-circle"
				v-if="help"
				v-tippy="{ content: help }"
			/>

			<label
				v-if="label"
				:for="name"
				class="form__label"
			>{{ label }}</label>`
		</div>

		<div class="form__field-line">

			<input
				v-if="!disabled"
				type="file"
				class="form__upload-input"
				:name="name"
				:id="name"
				:ref="name"
				@change="uploadFile($event)"
			/>

			<label
				v-if="!disabled"
				class="button button--outline form__upload-button"
				:for="name"
			>
				<font-awesome-icon icon="upload" class="button__icon" />
				{{ !!file ? 'Nahradit soubor' : 'Nahrát soubor' }}
			</label>

			<div class="form__upload">

				<Loading v-if="uploading" title="Nahrávám soubor" class="m-0" />

		    <Message v-if="error" type="error">{{error}}</Message>

				<template v-if="!uploading && !error">

					<template v-if="file">
						<a href="" @click.prevent="downloadFile">{{ file.puvodni_nazev }}</a>
					</template>

					<template v-else>
						zatím bez přílohy
					</template>

				</template>

			</div>

			<FieldStatusIcon
				v-if="statusOptions.type === 'icon'"
				:status="status"
				:options="statusOptions"
			/>
			<FieldStatusControl
				v-if="statusOptions.type === 'control'"
				:status="status"
				@updateStatus="updateStatus($event)"
			/>
		</div>
	</div>
</template>

<script>
import ApiClient from '@/services/ApiClient'
import { saveAs } from 'file-saver'

import Loading from '@/components/Common/Loading'
import Message from '@/components/Common/Message'
import FieldStatusIcon from '@/components/Forms/FieldStatusIcon'
import FieldStatusControl from '@/components/Forms/FieldStatusControl'

export default {
	name: 'FormFile',

	components: {
		Loading,
		Message,
		FieldStatusIcon,
		FieldStatusControl,
	},

	props: {
		file: {
			type: Object,
			default: null,
		},
		idZadosti: {
			type: Number,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		label: {
			type: String,
			default: null,
		},
		help: {											// pokud neni prazdna, tak je to napoveda nad ikonou s otaznickem
			type: String,
			default: null,
		},
		status: {										// stav schvaleni pole
			type: Number,
			default: null,
		},
		statusOptions: {
			type: Object,
			default: {
				type: 'none',						// typ stavove ikony - 'none', 'icon', 'controls'
				showAsEmpty: false,			// zobrazit prazdné místo, kde by normálně byla ikona?
				showApproved: true,			// zobrazovat zelenou ikonu schvaleneho pole?
			},
		},
	},

	data() {
		return {
			uploading: false,
			error: '',
		}
	},

	methods: {
		downloadFile() {
			// saveAs = function of FileSaver.js library
			ApiClient.get(
				'/soubor/' + this.file.nazev,
				{	responseType: 'blob' }, // important
			).then((response) => {
				saveAs(new Blob([response.data]), this.file.puvodni_nazev)
			})
		},


		uploadFile() {
			const file = this.$refs[this.name].files[0]

      let formData = new FormData()
      formData.append('file', file)
			formData.append('typ', 'priloha_informace_organizace')
      formData.append('id', this.idZadosti)
      formData.append('pole', this.name)

			this.uploading = true
			this.error = ''

      ApiClient.post('/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
        )
        .then(response => {
					if (response.data.success) {
						this.$emit('uploaded', response.data.data)
					} else {
						this.error = response.data.error
					}
        })
        .catch(error => {
          this.error = error.message
        })
				.then(() => {
					this.uploading = false
					this.$refs[this.name].value = ''
				})
		},

		updateStatus(status) {
			this.$emit('updateStatus', {
				field: this.name,
				status: status,
			})
		},
	},

}
</script>
