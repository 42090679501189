<template>
  <section>
    <h3 class="section__title">ŽÁDOSTI</h3>

    <Message v-if="error" type="error">
      {{error}}
    </Message>

    <Loading v-if="!dataLoaded" />

    <table v-if="dataLoaded && result.length !== 0" class="datagrid">
      <thead>
        <th>Dotační rok</th>
        <th>Stav žádosti</th>
      </thead>
      <tbody>
        <tr
          v-for="zadost in result"
          :key="zadost.id"
          @click="edit(zadost.dotacni_rok)"
        >
          <td>{{ zadost.dotacni_rok }}</td>
          <td>{{ zadost.stav_nazev }}</td>
        </tr>
      </tbody>
    </table>

    <p v-if="dataLoaded && result.length === 0" >
      <table class="datagrid">
        <tbody>
          <tr class="datagrid__row--non-hoverable">
            <td>Pro podání žádosti musíte nejprve vyplnit a nechat si schválit <router-link :to="{ name: 'OrganizaceInformace' }">informace o organizaci</router-link>.</td>
          </tr>
        </tbody>
      </table>
    </p>

  </section>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ZadostiService from '@/services/ZadostiService'

import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'ZadostiPrehled',

  components: {
    Message,
    Loading,
  },

  data() {
    return {
      error: '',
      zadosti: [],
      dataLoaded: false,
    }
  },

  computed: {
		...authComputed,

    result() {
      return this.zadosti
    },
  },

  methods: {
    edit(dotacniRok) {
      this.$router.push({ name: 'Zadost', params: { dotacniRok: dotacniRok } })
    },

    loadData() {
			ZadostiService
      .getZadostiOrganizace(this.userOrg.idOrganizace)
			.then(response => {
				if (response.data.success) {
					this.zadosti = response.data?.data || []
          this.dataLoaded = true
				} else {
          this.error += response.data.error
				}
			})
			.catch(error => {
        this.error += error.message
			})
    }
  },

  created() {
    this.loadData()
  }
}
</script>
