<template>
	<div class="filters">
		<div class="filter">
			<div class="filter__label">Zobrazení</div>
			<div class="filter__button-group">
				<button
					v-for="zobrazeni in moznostiZobrazeni"
					:key="zobrazeni.value"
					class="filter__button"
					:class="{ 'filter__button--active': zobrazeni.value === modelValue }"
					@click="$emit('update:modelValue', zobrazeni.value)"
				>
					{{ zobrazeni.label }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FiltrProjektyZobrazeni',

	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
	},

	data() {
		return {
			moznostiZobrazeni: [
				{ value: 'vychozi', label: 'Výchozí' },
				{ value: 'hodnotitele', label: 'Hodnotitelé' },
			],
		}
	},
}
</script>
