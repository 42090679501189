<template>
	<div class="filters">

		<div class="filter">
			<label class="filter__label">Rok</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': dotacniRok }"
				v-model="dotacniRok"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="2022">2022</option>
			</select>
		</div>

		<div class="filter size4">
			<label class="filter__label">Organizace</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': organizace}"
				v-model="organizace"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size2">
			<label class="filter__label">Kód</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': kod}"
				v-model="kod"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size5">
			<label class="filter__label">Název projektu</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': nazev}"
				v-model="nazev"
				@input="updateFilter"
			/>
		</div>

		<div class="filter">
			<label class="filter__label">Typ</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': typ}"
				v-model="typ"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="TYP_PROJEKTU_SLUZBA">služba</option>
				<option :value="TYP_PROJEKTU_IVH">IVH</option>
			</select>
		</div>

		<div class="filter">
			<label class="filter__label">Stav</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': stav}"
				v-model="stav"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="STAV_PROJEKTU_UZAMCENO">uzamčeno, probíhá kontrola</option>
				<option :value="STAV_PROJEKTU_VRACENO">vráceno k přepracování</option>
			</select>
		</div>

		<div class="filter" v-if="filterActive">
			<a
				href="#"
				class="filter__link"
				@click.prevent="clearFilter"
			>Zrušit filtr</a>
		</div>
	</div>
</template>

<script>
import {
	TYP_PROJEKTU_SLUZBA,
	TYP_PROJEKTU_IVH,
	STAV_PROJEKTU_PODAVANI,
	STAV_PROJEKTU_UZAMCENO,
	STAV_PROJEKTU_VRACENO,
	STAV_PROJEKTU_SCHVALENO,
	STAV_PROJEKTU_ZAMITNUTO,
} from '@/constants'

export default {
	name: 'FiltrProjekty',

	props: {
		filterValues: {
			type: Object,
			default: {},
		},
	},

	data() {
		return {
			dotacniRok: this.filterValues?.dotacniRok || '',
			organizace: this.filterValues?.organizace || '',
			kod: this.filterValues?.kod || '',
			nazev: this.filterValues?.nazev || '',
			typ: this.filterValues?.typ || '',
			stav: this.filterValues?.stav || '',
		}
	},

	computed: {
		filterActive() {
			return (
				!!this.dotacniRok
				|| !!this.organizace
				|| !!this.kod
				|| !!this.nazev
				|| !!this.typ
				|| !!this.stav
			)
		},
	},

	methods: {
		updateFilter() {
			this.$emit('updateFilter', {
				dotacniRok: this.dotacniRok,
				organizace: this.organizace,
				kod: this.kod,
				nazev: this.nazev,
				typ: this.typ,
				stav: this.stav,
			})
		},

		clearFilter() {
			this.dotacniRok = ''
			this.organizace = ''
			this.kod = ''
			this.nazev = ''
			this.typ = ''
			this.stav = ''

			this.updateFilter()
		}
	},


	created() {
		// constants
		this.TYP_PROJEKTU_SLUZBA = TYP_PROJEKTU_SLUZBA
		this.TYP_PROJEKTU_IVH    = TYP_PROJEKTU_IVH
		this.STAV_PROJEKTU_PODAVANI  = STAV_PROJEKTU_PODAVANI
		this.STAV_PROJEKTU_UZAMCENO  = STAV_PROJEKTU_UZAMCENO
		this.STAV_PROJEKTU_VRACENO   = STAV_PROJEKTU_VRACENO
		this.STAV_PROJEKTU_SCHVALENO = STAV_PROJEKTU_SCHVALENO
		this.STAV_PROJEKTU_ZAMITNUTO = STAV_PROJEKTU_ZAMITNUTO
	}
}
</script>
