<template>
	<section>

		<SectionHeader title="ŽADATEL O DOTACI" />

		<div class="infogrid">

			<table class="infogrid__table">
				<tr>
					<th class="infogrid__label">Název organizace</th>
					<td>{{ organizace.nazev }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">Právní forma</th>
					<td>{{ organizace.pravni_forma }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">Číslo registrace</th>
					<td>{{ organizace.cislo_registrace }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">Adresa organizace</th>
					<td>
						{{ organizace.sidlo_ulice + ' ' + cisloDomu  }},
						{{ organizace.sidlo_psc }} {{ organizace.sidlo_obec }}
					</td>
				</tr>
			</table>

			<table class="infogrid__table">
				<tr>
					<th class="infogrid__label">IČ</th>
					<td>{{ organizace.ic }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">Číslo účtu</th>
					<td>{{ organizace.cislo_uctu }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">Statutární zástupce organizace</th>
					<td>{{ organizace.statutar_jmeno }} {{ organizace.statutar_prijmeni }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">Telefon</th>
					<td>{{ organizace.statutar_telefon }}</td>
				</tr>
				<tr>
					<th class="infogrid__label">E-mail</th>
					<td>{{ organizace.statutar_email }}</td>
				</tr>
			</table>

		</div>

	</section>
</template>

<script>
import SectionHeader from '@/components/Common/SectionHeader'

export default {
	name: 'ZadostZadatelODotaci',

	components: {
		SectionHeader,
	},

	props: {
		organizace: {
			type: Object,
			required: true,
		}
	},

	computed: {
		cisloDomu() {
			return (
				this.organizace.sidlo_cp && this.organizace.sidlo_co
				? this.organizace.sidlo_cp + '/' + this.organizace.sidlo_co
				: this.organizace.sidlo_cp || this.organizace.sidlo_co
			)
		}
	}
}
</script>

<style>

</style>