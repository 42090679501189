import ApiClient from './ApiClient'

export default {

	login(credentials) {
		return ApiClient
			.post('/login', credentials)
	}

}
