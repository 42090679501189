<template>
	<input
		class="form__input"
		:type="type"
		:name="name"
		:id="name"
		:placeholder="placeholder"
		:disabled="disabled"
		:value="modelValue"
		@input="$emit('update:modelValue', $event.target.value)"
	>
</template>

<script>
export default {
	name: 'BaseInput',

	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: null,
		},
	},

}
</script>
