<template>
	<PageHeader :title="nazevProjektu">
		<div class="buttons" v-if="!projektDeleted">
			<button
				v-if="projekt.stav === STAV_PROJEKTU_UZAMCENO && ( isSuperAdmin || isAdmin)"
				class="button button--small button--outline"
				@click="dialogVraceni"
			>
				<font-awesome-icon icon="recycle" class="button__icon" />
				Vrátit projekt k přepracování
			</button>

			<button
				class="button button--small button--outline"
				@click="exportProjektuDoPdf"
			>
				<font-awesome-icon icon="pdf" class="button__icon" />
				Export projektu do PDF
			</button>

			<button
				v-if="projekt.stav === STAV_PROJEKTU_PODAVANI && !isGov"
				class="button button--small button--outline"
				@click="dialogPrejmenovani"
			>
				<font-awesome-icon icon="edit" class="button__icon" />
				Přejmenovat projekt
			</button>

			<button
				v-if="projekt.stav === STAV_PROJEKTU_PODAVANI || isSuperAdmin || isAdmin"
				class="button button--small button--danger button--outline"
				@click="dialogSmazani"
			>
				<font-awesome-icon icon="trash" class="button__icon" />
				Smazat projekt
			</button>
		</div>
	</PageHeader>

	<Modal
		v-if="modalOpened"
		:overlay="true"
	>
		<ProjektSmazatDialog
			v-if="modalDialog ==='smazat'"
			@submit="smazatProjekt"
			@cancel="closeModal"
		/>

		<ProjektPrejmenovatDialog
			v-if="modalDialog ==='prejmenovat'"
			:projekt="projekt"
			@submit="prejmenovatProjekt($event)"
			@cancel="closeModal"
		/>

		<ProjektVratitDialog
			v-if="modalDialog ==='vratit'"
			:projekt="projekt"
			@submit="vratitProjekt"
			@cancel="closeModal"
		/>


	</Modal>


	<Message type="info" v-if="message">
		{{ message }}
	</Message>

	<Message type="error" v-if="error">
		{{ error }}
	</Message>

	<Loading v-if="!dataLoaded || isDeleting" />

	<template v-if="dataLoaded && !projektDeleted">

		<Message type="info">
			Projekt je ve stavu <strong>{{ _TEMP_stavProjektu}}</strong>.
		</Message>

		<div class="panelgrid mt-5" v-if="projekt.stav === STAV_PROJEKTU_VRACENO">
			<div class="panelgrid__panel panelgrid__panel--danger">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Projekt vrácen k přepracování</h2>
					<p>Projekt Vám byl vrácen k přepracování. Zdůvodnění najdete v popisu vpravo.</p>
					<p>Opravte nedostatky, vraťte se na <router-link :to="{ name: 'Zadost' }">hlavní stranu žádosti</router-link> a žádost znovu odešlete ke kontrole.</p>
				</div>
			</div>

			<div class="panelgrid__panel panelgrid__panel--danger panelgrid__panel--twocolumns">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Důvod vrácení</h2>
					<pre>{{ projekt.poznamka_vraceni_projektu }}</pre>
				</div>
			</div>
		</div>

		<div class="panelgrid mt-5">

			<div class="panelgrid__panel">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">ŽÁDOST O DOTACI (část B1)</h2>
					<ul class="panelgrid__list">
						<li>Předmět žádosti</li>
						<li>Personální obsazení</li>
						<li>Komentář k rozpočtu</li>
					</ul>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-B1', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeProjektZamceny ? 'eye' : 'edit'" class="button__icon" />
						{{ jeProjektZamceny ? 'Zobrazit' : 'Upravit' }}
					</button>
				</div>
			</div>

			<div class="panelgrid__panel">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">CHARAKTERISTIKA A POPIS {{ projekt.typ == TYP_PROJEKTU_SLUZBA ? 'SLUŽBY' : 'PROJEKTU' }} (část B2)</h2>
					<ul class="panelgrid__list" v-if="projekt.typ == TYP_PROJEKTU_SLUZBA">
						<li>Informace o zařízení</li>
						<li>Cílová skupina</li>
						<li>Program primární prevence</li>
						<li>Zázemí a místa realizace</li>
						<li>Provozní doba</li>
						<li>Popis služby</li>
						<li>Změny oproti předchozímu roku</li>
					</ul>
					<ul class="panelgrid__list" v-if="projekt.typ == TYP_PROJEKTU_IVH">
						<li>Informace o zařízení</li>
						<li>Popis projektu</li>
						<li>Změny oproti předchozímu roku</li>
					</ul>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-B2', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeProjektZamceny ? 'eye' : 'edit'" class="button__icon" />
						{{ jeProjektZamceny ? 'Zobrazit' : 'Upravit' }}
					</button>
				</div>
			</div>

			<div class="panelgrid__panel">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">ROZPOČTY PROJEKTU (část C)</h2>
					<ul class="panelgrid__list">
						<li>Část C1 - Zdroje</li>
						<li>Část C2 - Rozpočet</li>
						<li>Část C3 - Pracovníci na HPP</li>
						<li>Část C4 - Pracovníci na DPČ</li>
						<li>Část C5 - Pracovníci na DPP</li>
					</ul>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-C', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeProjektZamceny ? 'eye' : 'edit'" class="button__icon" />
						{{ jeProjektZamceny ? 'Zobrazit' : 'Upravit' }}
					</button>
				</div>
			</div>

		</div>

	</template>

	<button
		v-if="!isGov"
		class="button button--outline mt-5"
		@click="$router.push({ name: 'Zadost' })"
	>
		<font-awesome-icon icon="arrow-left" class="button__icon" />
		Zpět na hlavní stranu žádosti
	</button>

</template>

<script>
import {
	TYP_PROJEKTU_SLUZBA,
	TYP_PROJEKTU_IVH,
	STAV_PROJEKTU_PODAVANI,
	STAV_PROJEKTU_UZAMCENO,
	STAV_PROJEKTU_VRACENO,
	STAV_PROJEKTU_SCHVALENO,
	STAV_PROJEKTU_ZAMITNUTO,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import { saveAs } from 'file-saver'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Modal from '@/components/Common/Modal'

import ProjektSmazatDialog from '@/components/Projekty/ProjektSmazatDialog'
import ProjektPrejmenovatDialog from '@/components/Projekty/ProjektPrejmenovatDialog'
import ProjektVratitDialog from '@/components/Projekty/ProjektVratitDialog'

export default {
	name: 'Projekt',

	components: {
		PageHeader,
		Message,
		Loading,
		Modal,
		ProjektSmazatDialog,
		ProjektPrejmenovatDialog,
		ProjektVratitDialog,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			modalOpened: false,
			modalDialog: '',

			error: '',
			message: '',
			dataLoaded: false,

			isDeleting: false,
			projektDeleted: false,

			projekt: {},
		}
	},

	computed: {
		...authComputed,

		nazevProjektu() {
			return (
				this.dataLoaded
				? this.projekt.kod + ': ' + this.projekt.nazev
				: 'PROJEKT'
			)
		},

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			return Date.now() < Date.parse('2021-11-11')
		},

		jeProjektZamceny() {
			return (
				this.projekt.stav === STAV_PROJEKTU_UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU_SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU_ZAMITNUTO
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},


		_TEMP_stavProjektu() {
			switch(this.projekt.stav) {
				case STAV_PROJEKTU_PODAVANI:
					return 'podávání žádosti'
					break
				case STAV_PROJEKTU_UZAMCENO:
					return 'uzamčeno, probíhá kontrola'
					break
				case STAV_PROJEKTU_VRACENO:
					return 'vráceno k přepracování'
					break
			}
			return ''
		}


	},

	methods: {

		closeModal() {
			this.modalOpened = false
			this.modalDialog = ''
		},

		vratitProjekt() {
			this.closeModal()
			this.loadData()
		},

		prejmenovatProjekt(data) {
			this.closeModal()
			this.projekt.nazev = data.nazev
			this.projekt.kod = data.kod
		},

		dialogVraceni() {
			this.modalDialog = 'vratit'
			this.modalOpened = true
		},

		dialogPrejmenovani() {
			this.modalDialog = 'prejmenovat'
			this.modalOpened = true
		},

		dialogSmazani() {
			this.modalDialog = 'smazat'
			this.modalOpened = true
		},

		smazatProjekt() {
			this.closeModal()
			this.isDeleting = true

			ProjektyService
			.deleteProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projektDeleted = true
					this.message = 'Projekt byl úspěšně smazán.'
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.isDeleting = false
			})
		},


		exportProjektuDoPdf() {
			ProjektyService
			.exportPDF(this.id)
			.then((response) => {
				saveAs(new Blob([response.data]), `projekt-${this.projekt.kod}.pdf`)
			})
		},


		loadData() {
			this.dataLoaded = false

			ProjektyService
			.getProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projekt = response.data.data.projekt
					this.dataLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

	},


	created() {
		// constants
		this.TYP_PROJEKTU_SLUZBA = TYP_PROJEKTU_SLUZBA
		this.TYP_PROJEKTU_IVH    = TYP_PROJEKTU_IVH
		this.STAV_PROJEKTU_PODAVANI  = STAV_PROJEKTU_PODAVANI
		this.STAV_PROJEKTU_UZAMCENO  = STAV_PROJEKTU_UZAMCENO
		this.STAV_PROJEKTU_VRACENO   = STAV_PROJEKTU_VRACENO
		this.STAV_PROJEKTU_SCHVALENO = STAV_PROJEKTU_SCHVALENO
		this.STAV_PROJEKTU_ZAMITNUTO = STAV_PROJEKTU_ZAMITNUTO

		this.loadData()
	},

}
</script>
