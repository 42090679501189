<template>

	<PageHeader title="PROJEKTY" />

	<Message type="error" v-if="error">
    {{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

  <div class="filters-container mt-4">
    <FiltrProjekty
      :filterValues="datagridFilter"
      @updateFilter="updateDatagridFilter($event)"
    />

    <FiltrProjektyZobrazeni
      v-if="isSuperAdmin || isAdmin"
      v-model="datagridView"
    />
  </div>


  <NastaveniHodnotitelu
    class="mt-4"
    v-if="datagridView === 'hodnotitele'"
    :krajskyKoordinator="krajskyKoordinator"
    :externiHodnotitel="externiHodnotitel"
    :vybraneProjekty="selected"
    @update="loadProjekty"
  />

  <div class="small mt-4" :class="{ 'color-danger': selected.length > 0 }">
    Označeno projektů: <strong>{{ selected.length }}</strong>
  </div>

	<table v-if="dataLoaded" class="datagrid mt-1">
		<thead>
			<tr>
        <th class="alc">
          Vybrat<br>
          <span class="small nowrap">
            <a class="link" href="#" @click.prevent="selectAll">vše</a>
            |
            <a class="link" href="#" @click.prevent="selectNone">nic</a>
          </span>
        </th>
        <th class="alc">Dotační rok</th>
        <th>Organizace</th>
        <th>Kód</th>
        <th>Název projektu</th>
        <th class="alc">Typ</th>

        <template v-if="datagridView === 'vychozi'">
          <th class="alc">Stav</th>
          <th class="alr">Celkové náklady</th>
          <th class="alr">Požadovaná dotace</th>
          <th class="alr">Celková dotace</th>
        </template>

        <template v-if="datagridView === 'hodnotitele'">
          <th class="alc">Dotační výbor</th>
          <th>KPK (hodnotí)</th>
          <th>KPK (jen vyjádření)</th>
          <th>Externí hodnotitel 1</th>
          <th>Externí hodnotitel 2</th>
          <th>Externí hodnotitel 3</th>
        </template>
			</tr>
		</thead>
		<tbody>
      <tr v-if="projektyResult.length === 0">
        <td colspan="9" class="alc p-5 color-danger">Nenalezeny žádné projekty.</td>
      </tr>

			<tr
				v-for="projekt in projektyResult"
				:key="projekt.id"
        :class="{ 'datagrid__row--selected': isSelected(projekt.id) }"
				@click="zobrazitProjekt(projekt.id)"
			>
        <td class="alc">
          <SimpleCheckbox
            :highlight="true"
            :checked="isSelected(projekt.id)"
            @click.stop="toggleSelected(projekt.id)"
          />
        </td>
        <td class="alc">{{ projekt.dotacni_rok }}</td>
        <td>{{ projekt.nazev_organizace }}</td>
        <td class="nowrap"><strong>{{ projekt.kod }}</strong></td>
        <td><strong>{{ projekt.nazev }}</strong></td>
        <td class="alc">{{ typProjektu(projekt.typ) }}</td>

        <template v-if="datagridView === 'vychozi'">
          <td class="alc">{{ projekt.stav_nazev }}</td>
          <td class="alr">{{ formatNumberCZ(projekt.celkove_naklady, '-') }}</td>
          <td class="alr">{{ formatNumberCZ(projekt.pozadovana_dotace, '-') }}</td>
          <td class="alr">-</td>
        </template>

        <template v-if="datagridView === 'hodnotitele'">
          <td class="alc">
            <SimpleCheckbox
              :checked="projekt.dotacni_vybor"
              @click.stop="setDotacniVybor(projekt.id, !projekt.dotacni_vybor)"
            />
          </td>
          <td>
            <SimpleSelect
              :value="projekt.krajsky_koordinator"
              :options="krajskyKoordinator"
              valueKey="id"
              optionKey="jmeno"
              :emptyValue="true"
              @click.stop
              @update="setHodnotitel(projekt.id, 'krajsky_koordinator', $event)"
            />
          </td>
          <td>
            <SimpleSelect
              :value="projekt.krajsky_komentator"
              :options="krajskyKoordinator"
              valueKey="id"
              optionKey="jmeno"
              :emptyValue="true"
              @click.stop
              @update="setHodnotitel(projekt.id, 'krajsky_komentator', $event)"
            />
          </td>
          <td>
            <SimpleSelect
              :value="projekt.ext_hodnotitel1"
              :options="externiHodnotitel"
              valueKey="id"
              optionKey="jmeno"
              :emptyValue="true"
              @click.stop
              @update="setHodnotitel(projekt.id, 'ext_hodnotitel1', $event)"
            />
          </td>
          <td>
            <SimpleSelect
              :value="projekt.ext_hodnotitel2"
              :options="externiHodnotitel"
              valueKey="id"
              optionKey="jmeno"
              :emptyValue="true"
              @click.stop
              @update="setHodnotitel(projekt.id, 'ext_hodnotitel2', $event)"
            />
          </td>
          <td>
            <SimpleSelect
              :value="projekt.ext_hodnotitel3"
              :options="externiHodnotitel"
              valueKey="id"
              optionKey="jmeno"
              :emptyValue="true"
              @click.stop
              @update="setHodnotitel(projekt.id, 'ext_hodnotitel3', $event)"
            />
          </td>
        </template>
			</tr>
		</tbody>
	</table>


</template>

<script>
import {
	TYP_PROJEKTU_SLUZBA,
	TYP_PROJEKTU_IVH,
	STAV_PROJEKTU_PODAVANI,
	STAV_PROJEKTU_UZAMCENO,
	STAV_PROJEKTU_VRACENO,
	STAV_PROJEKTU_SCHVALENO,
	STAV_PROJEKTU_ZAMITNUTO,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import { formatNumberCZ, formatDateCZ } from '@/utils/format.js'

import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import SimpleCheckbox from '@/components/Forms/Simple/SimpleCheckbox'
import SimpleSelect from '@/components/Forms/Simple/SimpleSelect'

import FiltrProjekty from '@/components/Toolbar/FiltrProjekty'
import FiltrProjektyZobrazeni from '@/components/Toolbar/FiltrProjektyZobrazeni'
import NastaveniHodnotitelu from '@/components/Toolbar/NastaveniHodnotitelu'

export default {
  name: 'Projekty',

  components: {
    PageHeader,
    Message,
    Loading,
    SimpleCheckbox,
    SimpleSelect,
    FiltrProjekty,
    FiltrProjektyZobrazeni,
    NastaveniHodnotitelu,
  },

  data() {
    return {
      error: '',

      projektyLoaded: false,
      projekty: [],
      selected: [],

      hodnotiteleLoaded: false,
      krajskyKoordinator: [],
      externiHodnotitel: [],

      datagridFilter: {},
      datagridView: 'vychozi',
    }
  },

  computed: {
    ...authComputed,

    dataLoaded() {
      return this.projektyLoaded && this.hodnotiteleLoaded
    },

    projektyResult() {
      return this.projekty.filter(projekt => {
        let result

        // zobrazujeme jen projekty, ktere nejsou rozpracovane
        result = projekt.stav !== this.STAV_PROJEKTU_PODAVANI

        // dotacni rok
        if (this.datagridFilter?.dotacniRok) {
          result = (
            result
            && projekt.dotacni_rok
            && projekt.dotacni_rok == this.datagridFilter.dotacniRok
          )
        }

        // nazev organizace
        if (this.datagridFilter?.organizace) {
          result = (
            result
            && projekt.nazev_organizace
            && projekt.nazev_organizace.toLowerCase().includes(this.datagridFilter.organizace.toLowerCase())
          )
        }

        // kod projektu
        if (this.datagridFilter?.kod) {
          result = (
            result
            && projekt.kod
            && projekt.kod.toLowerCase().includes(this.datagridFilter.kod.toLowerCase())
          )
        }

        // nazev projektu
        if (this.datagridFilter?.nazev) {
          result = (
            result
            && projekt.nazev
            && projekt.nazev.toLowerCase().includes(this.datagridFilter.nazev.toLowerCase())
          )
        }

        // typ projektu
        if (this.datagridFilter?.typ) {
          result = (
            result
            && projekt.typ
            && projekt.typ == this.datagridFilter.typ
          )
        }

        // stav projektu
        if (this.datagridFilter?.stav) {
          result = (
            result
            && projekt.stav
            && projekt.stav == this.datagridFilter.stav
          )
        }

        // vratime, zda projekt splnuje kriteria
        return result
      })
    }
  },

  methods: {
    formatNumberCZ,
    formatDateCZ,


    setHodnotitel(id, typ, newValue) {
      newValue = newValue ? Number(newValue) : null
      const projekt = this.findProject(id)
      const oldValue = projekt[typ]
      projekt[typ] = newValue

      const data = {
        id_projektu: [id],
        id_hodnotitele: newValue,
        typ_hodnotitele: typ
      }

      ProjektyService
      .nastavitHodnotitele(data)
      .then(response => {
        if (!response.data.success) {
          // pokud se nepovedlo, vrátíme data na původní hodnotu
          projekt[typ] = oldValue
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

    setDotacniVybor(id, newValue) {
      const projekt = this.findProject(id)
      const oldValue = projekt.dotacni_vybor
      projekt.dotacni_vybor = newValue

      const data = (
        newValue
        ? { nastavit: [id] }
        : { vymazat: [id] }
      )

      ProjektyService
      .nastavitDotacniVybor(data)
      .then(response => {
        if (!response.data.success) {
          // pokud se nepovedlo, vrátíme zaškrtnutí na původní hodnotu
          projekt.dotacni_vybor = oldValue
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

    findProject(id) {
      return this.projekty.find(projekt => projekt.id === id)
    },


    selectAll() {
      this.selected = this.projektyResult.map(projekt => projekt.id)
    },

    selectNone() {
      this.selected = []
    },

    toggleSelected(id) {
      if (this.isSelected(id)) {
        this.selected = this.selected.filter(x => x !== id)
      } else {
        this.selected.push(id)
      }
    },

    isSelected(id) {
      return this.selected.includes(id)
    },


    updateDatagridFilter(filterValues) {
      this.datagridFilter = filterValues
    },


    zobrazitProjekt(id) {
      this.$router.push({ name: 'Projekt', params: { id } })
    },

		typProjektu(typ) {
			switch (typ) {
				case TYP_PROJEKTU_SLUZBA:
					return 'služba'
				case TYP_PROJEKTU_IVH:
					return 'IVH'
			}
			return '-'
		},


    loadProjekty() {
      this.projektyLoaded = false
      ProjektyService
      .getProjekty()
      .then(response => {
        if (response.data.success) {
          this.projekty = response.data.data
          this.projektyLoaded = true
          // console.log(response.data.data)
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

    loadHodnotitele() {
      this.hodnotiteleLoaded = false
      ProjektyService
      .getHodnotitele()
      .then(response => {
        if (response.data.success) {
          this.krajskyKoordinator = response.data.data.krajsky_koordinator
          this.externiHodnotitel = response.data.data.externi_hodnotitel
          this.hodnotiteleLoaded = true
          // console.log(response.data.data)
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },


    loadData() {
      this.loadProjekty()
      this.loadHodnotitele()
    },

  },

  created() {
		// constants
		this.TYP_PROJEKTU_SLUZBA = TYP_PROJEKTU_SLUZBA
		this.TYP_PROJEKTU_IVH    = TYP_PROJEKTU_IVH
		this.STAV_PROJEKTU_PODAVANI  = STAV_PROJEKTU_PODAVANI
		this.STAV_PROJEKTU_UZAMCENO  = STAV_PROJEKTU_UZAMCENO
		this.STAV_PROJEKTU_VRACENO   = STAV_PROJEKTU_VRACENO
		this.STAV_PROJEKTU_SCHVALENO = STAV_PROJEKTU_SCHVALENO
		this.STAV_PROJEKTU_ZAMITNUTO = STAV_PROJEKTU_ZAMITNUTO

    this.loadData()
  }
}
</script>