<template>
	<select
		class="form__input"
		:value="modelValue"
		:name="name"
		:id="name"
		:disabled="disabled"
		@input="$emit('update:modelValue', $event.target.value)"
	>
		<option
			v-if="emptyValue"
			value=""
			:selected="(modelValue + '') === ''"
		>
			{{ emptyValueText }}
		</option>

		<option
			v-for="option in options"
			:key="option[valueKey]"
			:value="option[valueKey]"
			:selected="option[valueKey] === modelValue"
		>
			{{ option[optionKey] }}
		</option>

	</select>
</template>

<script>
export default {
	name: 'BaseSelect',

	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		valueKey: {
			type: String,
			required: true,
		},
		optionKey: {
			type: String,
			required: true,
		},
		emptyValue: {
			type: Boolean,
			default: true,
		},
		emptyValueText: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
	},

}
</script>
