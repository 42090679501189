<template>
	<div
		class="message"
		:class="type ? `message--${type}` : null"
	>
		<font-awesome-icon
			class="message__icon"
			:icon="icon"
		/>
		<div class="message__content">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'Message',

	props: {
		type: {
			type: String,
			default: null,
		},
	},

	computed: {
		icon() {
			switch (this.type) {
				case 'info':
					return 'info-circle'
				case 'error':
					return 'exclamation-triangle'
				case 'warning':
					return 'exclamation-circle'
				default:
					return null
			}
		},
	},

}
</script>

<style>

</style>