<template>
	<div>
			<h3 class="subsection-title">
				Přepočtené úvazky {{ this.typ == 1 ? '(služby)' : '(neslužbové (IVH) projekty)' }}
			</h3>

			<table class="inputtable" style="width: 70%">
				<thead>
					<tr>
						<th style="width: 40%;">&nbsp;</th>
						<th style="width: 20%;">Plán<br>na rok {{dotacniRok - 1}}</th>
						<th style="width: 20%;">Skutečný stav<br> v&nbsp;roce {{dotacniRok - 1}}</th>
						<th style="width: 20%;">Plán<br>na rok {{dotacniRok}}</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(radek, index) in data"
						:key="index"
					>
						<th :class="{subcategory: radek.nazev[0] == '-' }">
								{{radek.nazev}}
						</th>
						<td>
							<BaseInput
								class="w100"
								type="number"
								@input="updateModel"
								v-model="radek.minuly_plan"
								:name="`radek${index}_minuly_plan`"
								:disabled="disabled || jeSoucetRadek(index)"
							/>
						</td>
						<td>
							<BaseInput
								class="w100"
								type="number"
								@input="updateModel"
								v-model="radek.skutecnost"
								:name="`radek${index}_skutecnost`"
								:disabled="disabled || jeSoucetRadek(index)"
							/>
						</td>
						<td>
							<BaseInput
								class="w100"
								type="number"
								@input="updateModel"
								v-model="radek.plan"
								:name="`radek${index}_plan`"
								:disabled="disabled || jeSoucetRadek(index)"
							/>
						</td>
					</tr>
				</tbody>
			</table>

	</div>
</template>

<script>
import BaseInput from '@/components/Forms/Base/BaseInput'

export default {
	name: 'CastB1Uvazky',

	components: {
		BaseInput,
	},

	props: {
		modelValue: {
			type: [Number, String, Array],
			default: '',
		},
		typ: {
			type: [Number, String],
			required: true,
		},
		dotacniRok: {
			type: [Number, String],
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			data: null,
		}
	},

	methods: {
		jeSoucetRadek(index) {
			return (
				// u typu 1 (sluzba) jsou souctove radky prvni a posledni
				(this.typ == 1 && (index === 0 || index === 6))
				||
				// u typu 2 (IVH) je souctovy posledni radek
				(this.typ ==2 && index === 2)
			)
		},

		makeNumber(x) {
			if (x === undefined || x === null || x === '') {
				return 0
			}

			const num = x.toString().replace(',', '.')
			return isNaN(num) ? 0 : Number(num)
		},

		aktualizovatSoucetSluzby() {
			const soucetPrimaPece = { minuly_plan: 0, skutecnost: 0, plan: 0, }
			const soucetCelkem = { minuly_plan: 0, skutecnost: 0, plan: 0, }

			for (let i = 1; i <=4; i++) {
				soucetPrimaPece.minuly_plan += this.makeNumber(this.data[i].minuly_plan)
				soucetPrimaPece.skutecnost  += this.makeNumber(this.data[i].skutecnost)
				soucetPrimaPece.plan        += this.makeNumber(this.data[i].plan)
			}

			soucetCelkem.minuly_plan = soucetPrimaPece.minuly_plan + this.makeNumber(this.data[5].minuly_plan)
			soucetCelkem.skutecnost  = soucetPrimaPece.skutecnost  + this.makeNumber(this.data[5].skutecnost)
			soucetCelkem.plan        = soucetPrimaPece.plan        + this.makeNumber(this.data[5].plan)

			// zapiseme zpet do dat
			this.data[0].minuly_plan = soucetPrimaPece.minuly_plan.toFixed(2)
			this.data[0].skutecnost  = soucetPrimaPece.skutecnost.toFixed(2)
			this.data[0].plan        = soucetPrimaPece.plan.toFixed(2)

			this.data[6].minuly_plan = soucetCelkem.minuly_plan.toFixed(2)
			this.data[6].skutecnost  = soucetCelkem.skutecnost.toFixed(2)
			this.data[6].plan        = soucetCelkem.plan.toFixed(2)
		},

		aktualizovatSoucetIVH() {
			const soucetCelkem = { minuly_plan: 0, skutecnost: 0, plan: 0, }

			soucetCelkem.minuly_plan = this.makeNumber(this.data[0].minuly_plan) + this.makeNumber(this.data[1].minuly_plan)
			soucetCelkem.skutecnost  = this.makeNumber(this.data[0].skutecnost)  + this.makeNumber(this.data[1].skutecnost)
			soucetCelkem.plan        = this.makeNumber(this.data[0].plan)        + this.makeNumber(this.data[1].plan)

			// zapiseme zpet do dat
			this.data[2].minuly_plan = soucetCelkem.minuly_plan.toFixed(2)
			this.data[2].skutecnost  = soucetCelkem.skutecnost.toFixed(2)
			this.data[2].plan        = soucetCelkem.plan.toFixed(2)
		},

		updateModel() {
			if (this.typ == 1) {
				this.aktualizovatSoucetSluzby()
			} else {
				this.aktualizovatSoucetIVH()
			}
			this.$emit('update:modelValue', JSON.stringify(this.data))
		}
	},

	created() {
		if (this.modelValue) {
			this.data = JSON.parse(this.modelValue)
			return
		}

		// hodnota je zatím prázdná, vytvoříme výchozí pole
		if (this.typ == 1) {

			// služby
			this.data = [
				{ nazev: 'Pracovníci v přímé péči', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: '- Zdravotnický personál', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: '- Sociální profese', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: '- Pedagogický personál', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: '- Jiné', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: 'Ostatní pracovníci (mimo přímou péči)', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: 'Úvazky Celkem', minuly_plan: null, skutecnost: null, plan: null, },
			]

		} else if (this.typ == 2) {

			// neslužbové IVH projekty
			this.data = [
				{ nazev: 'Výzkumní pracovníci', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: 'Ostatní pracovníci', minuly_plan: null, skutecnost: null, plan: null, },
				{ nazev: 'Úvazky celkem', minuly_plan: null, skutecnost: null, plan: null, },
			]

		} else {
			this.data = []
		}

		this.updateModel()
	}

}
</script>