<template>
	<div class="filters">

		<div class="filter">
			<label class="filter__label">Rok</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': dotacniRok }"
				v-model="dotacniRok"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="2022">2022</option>
			</select>
		</div>

		<div class="filter size4">
			<label class="filter__label">Organizace</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': organizace}"
				v-model="organizace"
				@input="updateFilter"
			/>
		</div>

		<div class="filter">
			<label class="filter__label">Stav</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': stav}"
				v-model="stav"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="STAV_ZADOSTI_PODAVANI">podávání žádosti</option>
				<option :value="STAV_ZADOSTI_UZAMCENO">uzamčeno, probíhá kontrola</option>
				<option :value="STAV_ZADOSTI_VRACENO">vráceno k přepracování</option>
			</select>
		</div>

		<div class="filter" v-if="filterActive">
			<a
				href="#"
				class="filter__link"
				@click.prevent="clearFilter"
			>Zrušit filtr</a>
		</div>
	</div>
</template>

<script>
import {
	STAV_ZADOSTI_PODAVANI,
	STAV_ZADOSTI_UZAMCENO,
	STAV_ZADOSTI_VRACENO,
	STAV_ZADOSTI_SCHVALENO,
	STAV_ZADOSTI_ZAMITNUTO,
} from '@/constants'

export default {
	name: 'FiltrZadosti',

	props: {
		filterValues: {
			type: Object,
			default: {},
		},
	},

	data() {
		return {
			dotacniRok: this.filterValues?.dotacniRok || '',
			organizace: this.filterValues?.organizace || '',
			stav: this.filterValues?.stav || '',
		}
	},

	computed: {
		filterActive() {
			return (
				!!this.dotacniRok
				|| !!this.organizace
				|| !!this.stav
			)
		},
	},

	methods: {
		updateFilter() {
			this.$emit('updateFilter', {
				dotacniRok: this.dotacniRok,
				organizace: this.organizace,
				stav: this.stav,
			})
		},

		clearFilter() {
			this.dotacniRok = ''
			this.organizace = ''
			this.stav = ''

			this.updateFilter()
		}
	},


	created() {
		// constants
		this.STAV_ZADOSTI_PODAVANI  = STAV_ZADOSTI_PODAVANI
		this.STAV_ZADOSTI_UZAMCENO  = STAV_ZADOSTI_UZAMCENO
		this.STAV_ZADOSTI_VRACENO   = STAV_ZADOSTI_VRACENO
		this.STAV_ZADOSTI_SCHVALENO = STAV_ZADOSTI_SCHVALENO
		this.STAV_ZADOSTI_ZAMITNUTO = STAV_ZADOSTI_ZAMITNUTO
	}
}
</script>
