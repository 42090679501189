<template>

	<Modal
		v-if="editFormOpened"
		:overlay="true"
	>
		<UzivatelEditFormAdmin
			:id="editId"
			:onSubmit="userUpdated"
			:onCancel="closeEditForm"
		/>
	</Modal>

	<PageHeader title="Přehled uživatelů">
		<button
			class="button"
			@click="editUser(null)"
		>
			<font-awesome-icon icon="plus" class="button__icon" />
			Přidat uživatele
		</button>
	</PageHeader>

	<Message type="info">
		Přehled uživatelů můžete filtrovat nebo řadit pomocí prvků v záhlaví tabulky. Najetím myši na zkrácený text v tabulce se zobrazí celý. Kliknutím kamkoliv na řádek v tabulkce se otevře dialogové okno s možností editace.
	</Message>

	<Message v-if="error" type="error">
		{{error}}
	</Message>

	<Loading v-if="!dataLoaded" />
	<table v-else class="datagrid mt-4">
		<thead>
			<tr>
				<th>
					Příjmení
					<!-- <SortArrows field="prijmeni" :options="sortOptions" :sort="sortTable" /> -->
				</th>
				<th>
					Jméno
					<!-- <SortArrows field="jmeno" :options="sortOptions" :sort="sortTable" /> -->
				</th>
				<th>
					Organizace
					<!-- <SortArrows field="organizace" :options="sortOptions" :sort="sortTable" /> -->
				</th>
				<th>
					Role
					<!-- <SortArrows field="role" :options="sortOptions" :sort="sortTable" /> -->
				</th>
				<th>Telefon</th>
				<th>E-mail</th>
				<th>
					Stav
					<!-- <SortArrows field="prijmeni" :options="sortOptions" :sort="sortTable" /> -->
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="uzivatel in uzivateleResult"
				:key="uzivatel.id"
				@click="editUser(uzivatel.id)"
				:class="{
					'datagrid__row--disabled': !uzivatel.aktivni
				}"
			>
				<td>{{ uzivatel.prijmeni }}</td>
				<td>{{ uzivatel.jmeno }}</td>
				<td>{{ uzivatel.organizace }}</td>
				<td>{{ nazevRole(uzivatel.role) }}</td>
				<td>{{ uzivatel.telefon }}</td>
				<td>{{ uzivatel.email }}</td>
				<td>{{ uzivatel.aktivni ? 'Aktivní' : 'Deaktivovaný' }}</td>
			</tr>
		</tbody>
	</table>

</template>

<script>
import UzivateleService from '@/services/UzivateleService'

import Modal from '@/components/Common/Modal'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import SortArrows from '@/components/DataGrid/SortArrows'
import UzivatelEditFormAdmin from '@/components/Uzivatele/UzivatelEditFormAdmin'

export default {
  name: 'UzivateleAdmin',

  components: {
    Modal,
    PageHeader,
    Message,
    Loading,
    SortArrows,
    UzivatelEditFormAdmin,
  },

  data() {
    return {
      error: '',
      uzivatele: [],
      role: [],
      uzivateleLoaded: false,
      roleLoaded: false,
      sortOptions: [
        { field: 'prijmeni', dir: 'asc' },
        { field: 'jmeno', dir: 'asc' },
        { field: 'organizace', dir: 'asc' },
        { field: 'telefon', dir: 'asc' },
      ],
      editFormOpened: false,
      editId: null,
    }
  },

  computed: {
    dataLoaded() {
      return this.uzivateleLoaded && this.roleLoaded
    },

    uzivateleResult() {
      return this.uzivatele
    }
  },

  methods: {
    closeEditForm() {
      this.editFormOpened = false
    },

    userUpdated() {
      this.closeEditForm()
      this.uzivatele = []
      this.uzivateleLoaded = false
      this.loadDataUzivatele()
    },

    editUser(id) {
      this.editId = id
      this.editFormOpened = true
    },

    nazevRole(id) {
      return this.role.find(r => r.id === id)?.role
    },

    sortTable(field, dir) {
      this.sortOptions.find(o => o.field === field).dir = dir
      console.log(this.sortOptions)
    },

    sortByField(arr, field) {
      return true;
    },

    loadDataUzivatele() {
      UzivateleService.getUzivatele()
      .then(response => {
        if (response.data.success) {
          this.uzivatele = response.data.data
          this.uzivateleLoaded = true
        } else {
          this.error += response.data.error
        }
      })
      .catch(error => {
        this.error += error.message
      })
    }
  },

  created() {
    this.loadDataUzivatele()

    UzivateleService
    .getRole()
    .then(response => {
      if (response.data.success) {
        this.role = response.data.data
        this.roleLoaded = true
      } else {
        this.error += response.data.error
      }
    })
    .catch(error => {
      this.error += error.message
    })
  }

}
</script>