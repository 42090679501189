import axios from 'axios'

export const API_BASE_URL = process.env.VUE_APP_API_BASE

export default axios.create({
	baseURL: API_BASE_URL,
	// withCredentials: false,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
	}
})


