<template>
	<Dialog :title="title">

		<Loading v-if="id && !dataLoaded" />

		<form
			class="form form--size30"
			novalidate
			@submit.prevent="submit"
		>

			<Message type="info" class="mb-3">
				E-mailová adresa slouží jako uživatelské jméno (login).
			</Message>

			<Message
				class="mb-3"
				type="error"
				v-if="error"
			>
				{{ error }}
			</Message>

			<div class="form__field form__field--required">
				<label for="email" class="form__label">E-mail / login</label>
				<input
					class="form__input"
					type="text"
					name="email"
					id="email"
					v-model="email"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="jmeno" class="form__label">Jméno</label>
				<input
					class="form__input"
					type="text"
					name="jmeno"
					id="jmeno"
					v-model="jmeno"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="prijmeni" class="form__label">Příjmení</label>
				<input
					class="form__input"
					type="text"
					name="prijmeni"
					id="prijmeni"
					v-model="prijmeni"
				>
			</div>

			<div class="form__field">
				<label for="telefon" class="form__label">Telefon</label>
				<input
					class="form__input"
					type="text"
					name="telefon"
					id="telefon"
					v-model="telefon"
				>
			</div>


			<div class="form__buttons form__buttons--space-between">
				<div class="form__buttons-left">
					<button
						type="submit"
						class="button"
						@click.prevent="submit"
					>
						<font-awesome-icon icon="folder-open" class="button__icon" />
						Uložit uživatele
					</button>
					<button
						class="button button--outline"
						@click.prevent="cancel"
					>
						Storno
					</button>
				</div>
				<div
					class="form__buttons-right"
					v-if="role != ROLE_STATUTAR && dataLoaded"
				>
					<button
						class="button button--outline button--danger"
						v-if="id && aktivni"
						@click.prevent="submit({ aktivni: false })"
					>
						<font-awesome-icon icon="trash" class="button__icon" />
						Deaktivovat
					</button>
					<button
						class="button button--outline button--success"
						v-if="id && !aktivni"
						@click.prevent="submit({ aktivni: true })"
					>
						<font-awesome-icon icon="recycle" class="button__icon" />
						Aktivovat
					</button>
				</div>
			</div>

		</form>
	</Dialog>
</template>

<script>
import { ROLE_STATUTAR, ROLE_PRACOVNIK_ORGANIZACE } from '@/constants'
import { authComputed } from '@/store/helpers.js'
import UzivateleService from '@/services/UzivateleService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'UzivatelEditForm',

	props: {
		id: {
			type: Number,
			default: null,
		},
		onSubmit: {
			type: Function,
			default: null
		},
		onCancel: {
			type: Function,
			default: null
		}
	},

	components: {
		Dialog,
		Message,
		Loading,
	},

	data() {
		return {
			title: 'Nový uživatel aplikace',
			email: '',
			jmeno: '',
			prijmeni: '',
			telefon: '',
			role: null,
			kraj: null,
			datumExpirace: null,
			aktivni: true,

			dataLoaded: false,
			error: '',
		}
	},

	computed: {
		...authComputed,
	},

	methods: {
		submit(options = {}) {
			if (!this.checkForm()) return

			console.log(this.role)
			console.log(this.role || this.ROLE_PRACOVNIK_ORGANIZACE)

			const uzivatel = {
				email: this.email,
				jmeno: this.jmeno,
				prijmeni: this.prijmeni,
				id_organizace: this.userOrg.idOrganizace,
				telefon: this.telefon,
				role: this.role || this.ROLE_PRACOVNIK_ORGANIZACE,
				id_kraj: this.kraj || null,
				datum_expirace: this.datumExpirace || null,
				aktivni: this.aktivni,
				...options,
			}

			let save;

			if (this.id) {
				uzivatel.id = this.id,
				save = UzivateleService.updateUzivatel
			} else {
				save = UzivateleService.createUzivatel
			}

			save(uzivatel)
			.then(response => {

				console.log(response.data)

				if (response.data.success) {
					if (this.onSubmit) this.onSubmit()
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.data?.error || 'Při pokusu o uložení došlo k chybě'
			})
		},

		cancel() {
			if (this.onCancel) this.onCancel()
		},

		checkForm() {
			this.error = '';

			if (!(this.email && this.jmeno && this.prijmeni)) {
				this.error = 'Vyplňte všechny poviné údaje'
				return false
			}

			if (!(this.email.includes('@') && this.email.includes('.'))) {
				this.error = 'Neplatná emailová adresa'
				return false
			}

			return true
		}
	},

	created() {
		// constants
		this.ROLE_STATUTAR = ROLE_STATUTAR
		this.ROLE_PRACOVNIK_ORGANIZACE = ROLE_PRACOVNIK_ORGANIZACE


		if(this.id) {
			this.title = 'Editace uživatele'

			UzivateleService.getUzivatel(this.id)
				.then(response => {
					if (response.data.success) {
						this.email = response.data.data.email
						this.jmeno = response.data.data.jmeno
						this.prijmeni = response.data.data.prijmeni
						this.telefon = response.data.data.telefon
						this.role = response.data.data.role
						this.kraj = response.data.data.id_kraj
						this.datumExpirace = response.data.data.datum_expirace
						this.aktivni = response.data.data.aktivni

						this.dataLoaded = true
					} else {
						this.error = response.data.error
					}
				})
				.catch(error => {
					this.error = error.response.data.error || 'Chyba při načítání uživatele'
				})
		}


	}

}
</script>
